.message-box {
	padding: 50px;
	border: 2px dashed #ccc;
	/*line-height: 30px;*/
	max-width: 100%;
	flex: 100%;
}
.message-box i {
	font-size: 18px;
	color: #000;
	margin-right: 15px;
}

.message-box p,
.message-box small {
	color: #777;
}

.price-box {
	padding: 20px;
	display: inline-flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	list-style: none;
}
.price-box li {
	width: 49%;
	color: #000;
	font-size: 16px;
	font-weight: 600;
}
ul.price-box li::before {
	display: none;
}
ul.price-box li:nth-child(odd) {
	text-align: left;
	padding-left: 20px;
}

.btn-search {
	height: auto;
}
.message-box label {
	margin-bottom: 0px;
	font-size: 18px;
	color: #000;
	font-weight: normal;
	display: block;
}

.register-form .form-control {
	border: 0px;
	margin: 0px;
	width: calc(100% - 30px);
	border-bottom: 1px solid #c6c6c6;
	padding: 0;
	box-shadow: none;
	font-size: 18px;
	height: 55px;
	color: #000;
	font-family: "Chronicle Text G1";
}
.register-form .form-control::placeholder {
	color: #c6c6c6;
}
.register-form .form-control:hover .register-form .form-control:focus {
	border: 0px;
	box-shadow: 0px;
	outline: 0px;
}
.equal-space {
	margin: 50px 0px;
}
.form-check .form-check-input {
	padding: 5px;
	height: 20px;
	width: 19px;
}
.form-check-label {
	margin-left: 10px;
}
.form-group {
	margin-bottom: 0rem;
	margin: 15px 0px;
}

.order-summary h3 {
	margin-bottom: 20px;
	padding-left: 16px;
}
.total-order-summary {
	display: flex;
	justify-content: space-between;
	width: 80%;
}
.total-detail {
	padding-left: 20px;
}
.total-mrp {
	padding-right: 20px;
}
.total-summary {
	border: 1px solid #d9d9d9;
	padding: 10px 0;
	margin-top: 10px;
}
/* mediaquery css starts */
@media (max-width: 1024px) {
	.order-summary h3 {
		margin-top: 45px;
	}
	.total-order-summary {
		width: 100%;
	}
	.order-summary.col-md-4.col-sm-12 {
		flex: 100%;
		max-width: 100%;
	}
}
@media (max-width: 768px) {
	.equal-space {
		margin: 0;
	}
}
@media (max-width: 767px) {
	.message-box {
		margin: auto;
		padding: 20px;
		max-width: 100%;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.register-form .col-sm-8 {
		-ms-flex: 100%;
		flex: 100%;
		max-width: 100%;
	}

	.message-box label {
		font-size: 16px;
	}
}
/* mediaquery css ends */
