#thesoulcompany {
	padding: 100px 155px;
	color: #fff;
	font-size: 16px;
}

#thesoulcompany h2 {
	font-size: 80px;
	color: #fff;
	line-height: 85px;
	text-align: left;
}
#thesoulcompany h2::after {
	content: none;
}
#thesoulcompany .border-btn {
	border: 1px solid transparent;
	padding: 5px 30px;
	margin: 40px 0 50px;
	display: table;
	background: #f15b3b;
	color: #fff;
}
#thesoulcompany .border-btn:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
}

#thesoulcompany p {
	padding-bottom: 10px;
	width: 80%;
}
/* meadiaquery starts */
@media (max-width: 1366px) {
	#thesoulcompany h2 {
		padding-bottom: 30px;
	}
}
@media (max-width: 1280px) {
	#thesoulcompany h2 {
		font-size: 64px;
		line-height: 74px;
	}
}
@media (max-width: 1024px) {
	#thesoulcompany h2 {
		font-size: 50px;
		line-height: 55px;
	}
	#thesoulcompany {
		padding: 100px 50px;
	}
	#thesoulcompany .border-btn {
		margin: 28px 0 50px;
	}
	#thesoulcompany p {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.fullwidth {
		flex-basis: auto;
	}
	#thesoulcompany h2 {
		font-size: 65px;
		line-height: 70px;
	}
}
@media (max-width: 767px) {
	#thesoulcompany h2 {
		font-size: 45px;
		line-height: 56px;
	}
	#thesoulcompany p {
		width: 100%;
	}
}
/* mediaquery ends */
