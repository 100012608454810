#home-journey {
	margin-bottom: 50px;
}

#home-journey .part1 {
	margin-left: 0;
}

#home-journey .part1,
#home-journey .part2,
#home-journey .part3,
#home-journey .part {
	width: 100%;
	padding: 0;
}

#home-journey h3 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 40px;
	font-family: "Chronicle Text G1";
}

#home-journey img {
	width: 100%;
}

#home-journey .item {
	position: relative;
}

#home-journey .item .overlay {
	cursor: pointer;
}

#home-journey .item img {
	overflow: hidden;
	width: 100%;
}

#home-journey .item span {
	opacity: 0;
	background-color: #f15b3b;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: 0.4s all ease;
}

#home-journey .item:hover span {
	opacity: 1;
	transition: 0.4s all ease;
}

#home-journey .item nav.Link {
	display: block;
	overflow: hidden;
}

#home-journey .item span {
	text-align: left;
	padding: 30px 30px;
}

#home-journey .item span p {
	color: #fff;
	font-size: 14px;
	line-height: 22px;
}

#home-journey .item button:focus {
	outline: none;
}

#home-journey .item .nav-link {
	padding: 0;
}
#home-journey .slick-slide div {
	margin: 15px;
}
#home-journey .slick-dots li button:before {
	color: #f15b3b;
}
/* meadiaquery starts */

@media (max-width: 1280px) {
	#home-journey {
		margin-bottom: 0;
	}
	h2 {
		font-size: 60px;
		line-height: 65px;
	}
}

@media (max-width: 768px) {
	#home-journey .row {
		display: block;
	}
	#home-journey .part1,
	#home-journey .part2,
	#home-journey .part3,
	#home-journey .part {
		margin: 0 auto;
		margin-bottom: 30px;
		width: 85%;
	}
	#home-journey h3 {
		font-size: 70px;
	}
	#home-journey .item span p {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	#home-journey h3 {
		font-size: 45px;
	}
	#home-journey .item span p {
		font-size: 18px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 0px 20px;
	}
	#home-journey .slick-dots {
		bottom: auto;
	}
	#home-journey .slick-dots li {
		margin: 0;
	}
}

/* mediaquery ends */
