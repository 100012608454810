.sorting {
	margin: 50px 18px 25px;
}
.align-right {
	display: flex;
	justify-content: flex-end;
}
.align-right h4 {
	display: inline-block;
	margin-right: 20px;
	font-size: 16px;
	line-height: 40px;
}

button.show-more {
	font-size: 20px;
	color: #f15b3b;
	text-decoration: none;
	border: 1px solid #f15b3b;
	border-radius: 0;
	padding: 10px 30px;
	font-size: 16px;
	font-weight: 600;
}
button.show-more:hover {
	background: #f15b3b;
	color: #fff;
}
button.show-more:hover .arrorright {
	color: #fff;
}
.sort-list-number h6,
.sort-list-number h6 a {
	font-size: 16px;
	color: #000;
}
.angle-right {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
.img-like {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 20px;
	background-color: #fff;
	padding: 15px;
	color: #f15b3b;
	border: none;
}
.img-like:hover {
	background: #f15b3b;
	color: #fff;
}
.img-like:focus {
	outline: none;
}
.img-like.wishlisted {
	background-color: #f15b3b;
	color: #fff;
}
.imgwrap {
	position: relative;
}
.sort-list-number {
	padding-top: 10px;
}
.sort-dropdown.icon-angle-down.dropdown {
	border: 1px solid #d9d9d9;
}
.sort-dropdown.icon-angle-down .btn-primary {
	height: auto;
	padding: 10px 15px;
	margin: 0;
}
.NoExpToShow {
	margin: 100px auto 0;
}
/* mediaquery css starts */
@media (max-width: 767px) {
	.align-right {
		display: flex;
		justify-content: flex-start;
		padding: 0;
	}
	.align-right form {
		width: 75%;
	}
	.sort-list-number {
		margin-bottom: 15px;
		padding: 0;
	}
}
/* mediaquery css ends */
