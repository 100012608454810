.abtbanner img {
	width: 100%;
}
.no-space {
	padding: 0;
}
.visible-tab {
	display: none;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.aboutbanner {
		height: 410px;
	}
}
@media (max-width: 1366px) {
	.aboutbanner {
		height: 350px;
	}
}
@media (max-width: 1280px) {
	.aboutbanner {
		height: 300px;
	}
}
@media (max-width: 1024px) {
	.aboutbanner {
		height: 250px;
	}
}
@media (max-width: 768px) {
	.aboutbanner {
		height: 200px;
	}
	.visible-tab {
		display: block;
	}
}
@media (max-width: 767px) {
	.aboutbanner {
		height: 155px;
	}
	.visible-tab {
		display: none;
	}
}
/* mediaquery css ends */
