.search-wrap {
	margin-bottom: 150px;
}
.search-wrap span {
	font-size: 18px;
	color: #8a8a8a;
	display: block;
	text-align: center;
	margin-bottom: 60px;
}
.search-div h6 {
	font-size: 18px;
	color: #8a8a8a;
	margin-bottom: 15px;
}
.search-div p {
	font-size: 26px;
	color: #000;
}
.search-div {
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

/* mediaquery css starts */
@media (max-width: 768px) {
	.search-div p {
		font-size: 20px;
	}
}
/* mediaquery css ends */
