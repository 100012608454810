@charset "utf-8";

/*====limited reset====*/
html,
body,
div,
section,
article,
aside,
header,
hgroup,
footer,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
time,
span,
em,
strong,
img,
ol,
ul,
li,
figure,
canvas,
video {
	margin: 0;
	padding: 0;
	border: 0;
}

/*====html5 display rule====*/
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
menu,
nav,
section,
summary {
	display: block;
}

/*====common css====*/
html,
body {
	height: 100%;
	width: 100%;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	outline: 0;
	color: #555555;
}

a:hover,
a:focus {
	text-decoration: none;
	color: #555555;
	outline: none;
}

/* @font-face {
    font-family: 'Chronicle Text G1';
    src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'), url('../fonts/CenturyGothic.woff') format('woff'), url('../fonts/CenturyGothic.ttf') format('truetype'), url('../fonts/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
	font-family: "Chronicle Text G1";
	src: url("fonts/ChronicleTextG1-Roman.eot");
	src: url("fonts/ChronicleTextG1-Roman.eot?#iefix") format("embedded-opentype"),
		url("fonts/ChronicleTextG1-Roman.woff2") format("woff2"),
		url("fonts/ChronicleTextG1-Roman.woff") format("woff"),
		url("fonts/ChronicleTextG1-Roman.ttf") format("truetype"),
		url("fonts/ChronicleTextG1-Roman.svg#ChronicleTextG1-Roman") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Chronicle Text G1 Semi";
	src: url("fonts/ChronicleTextG1-Semi.eot");
	src: url("fonts/ChronicleTextG1-Semi.eot?#iefix") format("embedded-opentype"),
		url("fonts/ChronicleTextG1-Semi.woff2") format("woff2"),
		url("fonts/ChronicleTextG1-Semi.woff") format("woff"),
		url("fonts/ChronicleTextG1-Semi.ttf") format("truetype"),
		url("fonts/ChronicleTextG1-Semi.svg#ChronicleTextG1-Semi") format("svg");
	font-weight: 600;
	font-style: normal;
} */
body {
	font-family: "Tenor Sans", sans-serif;
	font-size: 16px;
	line-height: 26px;
	margin: 0px;
	padding: 0px;
	color: #323232;
	-webkit-font-smoothing: subpixel-antialiased;
	overflow-x: hidden;
}
::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}
::-webkit-scrollbar-track-piece {
	background-color: #fff;
}
::-webkit-scrollbar-thumb:vertical {
	height: 30px;
	background-color: #f15b3b;
}
.submit-btn {
	background: #323232 none repeat scroll 0 0;
	border: medium none;
	color: #f15b3b;
	display: block;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
	width: 100%;
	text-transform: uppercase;
	text-decoration: none;
}

.form-control {
	background-color: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 0;
	box-shadow: none;
	color: #000;
	display: block;
	font-size: 16px;
	height: 60px;
	padding: 6px 25px;
	width: 100%;
}
h2 {
	font-size: 40px;
	color: #000000;
	padding: 60px 0;
	position: relative;
	text-align: center;
}
.line {
	position: absolute;
	z-index: 2;
	content: "";
	width: 10%;
	bottom: 40px;
	height: 2px;
	background: #f15b3b;
	left: 0;
	right: 0;
	margin: 0 auto;
	-webkit-animation: drawline 0.75s 1 forwards;
	-moz-animation: drawline 0.75s 1 forwards;
	-o-animation: drawline 0.75s 1 forwards;
	animation: drawline 0.75s 1 forwards;
}
@-webkit-keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}
@-moz-keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}
@-o-keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}
@keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}

card {
	border: none;
	border-radius: 0px;
}
.card-title {
	font-size: 28px;
	color: #000;
	margin-top: 20px;
	text-transform: none;
}
.card-text {
	font-size: 18px;
	color: #000;
	margin-bottom: 20px;
}
.card-text.limit-line-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 26px; /* fallback */
	max-height: 52px; /* fallback */
	/* -webkit-line-clamp: 2;  */
	-webkit-box-orient: vertical;
}

.price.card-text {
	font-size: 24px;
	color: #f15b3b;
	margin-bottom: 30px;
}

.border-btn {
	border: 1px solid #000;
	padding: 5px 30px;
	margin: 10px 0 0px;
	display: table;
	border-radius: 0px;
	background: transparent;
	color: #000;
	border-radius: 0;
}
.border-btn:hover {
	background: #000;
	color: #fff;
	outline: none;
	border-color: #000;
}
.border-btn:focus {
	outline: none;
}

.card-img-top {
	border-radius: 0px;
}
.show-more {
	color: #000;
	font-size: 20px;
}

.show-more i {
	padding-left: 20px;
}

.expcard {
	padding-bottom: 80px;
}
.card {
	border: none;
	border-radius: 0;
}
.container-grid {
	padding: 0 35px;
}
.navbar-light .navbar-nav a {
	color: #000;
}
.btn:focus,
.btn:visited {
	outline: none;
}
.clear {
	clear: both;
}

.form-control:focus,
.btn.focus,
.btn:focus {
	box-shadow: none;
}
.txt-orange {
	color: #f15b3b;
}
.cmn-btn {
	border: 1px solid transparent;
	padding: 5px 30px;
	margin: 10px 0 0px;
	display: table;
	border-radius: 0px;
	background: #f15b3b;
	color: #fff;
	border-radius: 0;
}
.cmn-btn:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
}
h4 {
	font-size: 30px;
	line-height: 35px;
	color: #f15b3b;
}
option {
	padding: 10px;
	line-height: 26px;
	font-size: 18px;
}
.card-body {
	padding: 10px 0;
}
.err_text,
.err_text a {
	color: red !important;
	font-size: 16px;
	margin-top: 10px;
}
.err_text a:hover {
	color: #f15b3b;
	text-decoration: underline;
}
.form-text {
	font-size: 16px;
	color: red;
}
.hidden-xs {
	display: block;
}
.visible-xs {
	display: none;
}
.fixed-top {
	background: #fff;
}
.navbar-light .navbar-brand {
	margin-left: 30px;
}
.logreg.navbar-nav {
	margin-right: 30px;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.left-menu {
		padding-left: 50px;
	}
	.navbar-light .navbar-nav a {
		padding: 15px 15px;
	}
	.card-title {
		font-size: 30px;
	}
	h2 {
		font-size: 54px;
		line-height: 64px;
	}
}
@media (max-width: 1280px) {
	form#topsearch {
		width: 245px;
	}
	form#topsearch .form-control {
		padding: 25px;
	}
	form#topsearch .submit-btn {
		width: 10%;
	}
	h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 1024px) {
	.left-menu {
		padding-left: 0;
	}
	form#topsearch {
		width: 170px;
	}
	.navbar-light .navbar-nav a {
		padding: 15px 10px;
		font-size: 16px;
	}
	.arrorright {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: -37px;
	}
	.navbar-brand img {
		width: 82%;
	}
	.rega {
		margin-left: 5px;
	}
	h2 {
		font-size: 46px;
		line-height: 55px;
	}
	#home-journey .item span p {
		color: #fff;
		font-size: 14px;
		line-height: 20px;
	}
	#home-journey .item span {
		padding: 15px 15px;
	}
	.card-title {
		font-size: 24px;
	}
	.price.card-text {
		font-size: 18px;
	}
	.card-text {
		font-size: 14px;
	}
	.card-text.limit-line-2 {
		line-height: 20px;
		max-height: 60px;
	}
}
@media (max-width: 768px) {
	.card-title.h5 {
		font-size: 30px;
	}
	.price.card-text {
		font-size: 24px;
	}
	a.border-btn.btn.btn- {
		font-size: 18px;
	}
	.card-text {
		font-size: 21px;
	}
	.navbar-light .navbar-toggler {
		color: #f15b3b;
		border-color: transparent;
	}
	.navbar-light .navbar-nav a:first-child {
		padding-left: 0;
	}
	.navbar-light .navbar-nav a {
		padding: 5px 10px 5px;
		font-size: 18px;
		text-align: center;
	}
	.logreg {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: 0;
		padding: 5px 10px;
		margin: 15px auto 0;
		display: inline-block;
		width: 40%;
		text-align: center;
	}
	.logreg .navbar-light .navbar-nav a:last-child {
		padding-top: 0;
	}
	.navbar-collapse {
		padding: 50px 0;
	}
	.search-phn input.form-control.form-control {
		border: 0;
		width: 50%;
		padding: 0;
	}
	.search-phn.form-inline {
		width: 30%;
	}
	button.searchbtn.btn.btn-primary {
		background: transparent;
		border: 0;
	}
	form#topsearch {
		display: none !important;
	}
	form#topsearchphn {
		display: block !important;
	}
	h4 {
		font-size: 24px;
	}
	.navbar-light .navbar-brand {
		margin-left: 10px;
	}
	.logreg.navbar-nav {
		margin-right: 0;
	}
	.hidden-xs {
		display: none;
	}

	.card-text.limit-line-2 {
		line-height: 30px;
	}
}
@media (max-width: 767px) {
	.visible-xs {
		display: block;
	}
	h2 {
		font-size: 40px;
		line-height: 45px;
	}
	.card-text {
		font-size: 18px;
	}
	.card-body {
		padding: 10px 0;
	}
	.container-grid {
		padding: 0 0;
	}
	.navbar-light .navbar-toggler {
		color: #f15b3b;
		border-color: transparent;
	}
	.navbar-light .navbar-nav a:first-child {
		padding-left: 0;
	}
	.navbar-light .navbar-nav a {
		padding: 5px 0 5px;
		font-size: 18px;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
	.logreg {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: 0;
		padding: 5px 10px;
		margin: 15px auto 0;
		display: inline-block;
		width: 40%;
		text-align: center;
	}
	.logreg .navbar-light .navbar-nav a:last-child {
		padding-top: 0;
	}
	.navbar-collapse {
		padding: 50px 0;
	}
	.search-phn input.form-control.form-control {
		border: 0;
		width: 50%;
		padding: 0;
	}
	.search-phn.form-inline {
		width: 30%;
	}
	button.searchbtn.btn.btn-primary {
		background: transparent;
		border: 0;
	}
	form#topsearch {
		display: none;
	}
	@-webkit-keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	@-moz-keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	@-o-keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	@keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	.navbar {
		padding: 15px 0;
	}
	a.border-btn {
		margin: 0 auto;
	}
}
/* mediaquery css ends */

/* Global styles */
form .suc_text {
	color: #48ac15;
	font-size: 16px;
	margin-top: 10px;
}
.go-back-link {
	margin-top: 10px;
	margin-bottom: 10px;
}
.go-back-link .link {
	font-weight: 600;
}
.go-back-link .link > svg {
	margin-right: 0.4rem;
	margin-bottom: -0.1rem;
}
.cmn-btn-inverse.btn.btn-primary {
	margin: 0;
	color: #f15b3b;
	background-color: #fff;
	border-color: #f15b3b;
	border-radius: 0;
}
.cmn-btn-inverse.btn.btn-primary:hover,
.cmn-btn-inverse.btn.btn-primary:focus {
	background-color: #f15b3b;
	color: #fff;
}
.cmn-btn-inverse.btn.btn-primary:disabled {
	cursor: not-allowed;
}
.cmn-btn-inverse.btn.btn-primary:disabled:hover,
.cmn-btn-inverse.btn.btn-primary:disabled:focus {
	background-color: #fff;
	color: #f15b3b;
}

/* Custom Alert */
.alert {
	position: fixed;
	margin-bottom: 0;
	background-color: #eee;
	top: 35%;
	left: 50%;
	transform: translateX(-50%);
	min-width: 25%;
	z-index: 1031;
	border-radius: 0;
	text-align: center;
}
.alert-dismissible .close {
	top: 50%;
	transform: translateY(-50%);
	padding: 0.5rem 1rem;
	outline: none;
}
.alert-custom {
	background-color: #e0e0e0;
	color: #fff;
}
.alert-suces {
	background-color: #39ae2b;
	color: #fff;
}
.alert-failr {
	background-color: #f15b3b;
	color: #fff;
}

/* Custom Modal */
.custom-modal {
	max-width: 26vw;
}
.custom-modal .modal-header {
	padding-bottom: 0;
}
.custom-modal .modal-title {
	font-size: 1.3rem;
	color: #f15b3b;
	font-weight: 500;
}
.custom-modal .modal-footer {
	padding-top: 0;
}

/* Media queries new */
@media (max-width: 1023px) {
	.custom-modal {
		max-width: 35vw;
	}
}
@media (max-width: 991px) {
	.custom-modal {
		max-width: 65vw;
	}
}
@media (max-width: 768px) {
	.go-back-link {
		margin-top: 50px;
	}
	.navbar {
		padding: 0;
	}
}
@media (max-width: 767px) {
	.custom-modal {
		max-width: 85vw;
		margin: 0 auto;
	}
}
