.featuredexp {
	padding: 0 30px;
}
.featuredexphold {
	margin-bottom: 100px;
	margin-top: 20px;
}
a.show-more {
	color: #f15b3b;
	text-decoration: none;
	border: 1px solid #f15b3b;
	border-radius: 0;
	padding: 10px 30px;
	font-size: 16px;
	font-weight: 600;
}
.arrorright {
	font-size: 16px;
	margin-left: 10px;
	color: #f15b3b;
	display: inline-block;
	vertical-align: middle;
}
a.show-more:hover {
	color: #fff;
	background: #f15b3b;
}
a.show-more:hover .arrorright {
	color: #fff;
}
.card {
	overflow: hidden;
}
.card-img-top {
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 100%;
	transition: 0.4s all ease;
	transform: scale(1);
}
.card-img-top:hover {
	transition: 0.4s all ease;
	transform: scale(1.08);
}
.imgwrap {
	overflow: hidden;
}

/* meadiaquery starts */

@media (max-width: 1280px) {
	.card-title.h5 {
		margin-top: 0;
	}
	.card-title {
		font-size: 26px;
	}
	.price.card-text {
		font-size: 22px;
	}
}
@media (max-width: 1024px) {
	.show-more .arrorright {
		margin-left: 10px;
	}
}
@media (max-width: 768px) {
	.expcard {
		flex: 100%;
		max-width: 100%;
	}
	.expimg img {
		min-height: auto;
		max-height: inherit;
	}
}
@media (max-width: 767px) {
	.featuredexp {
		padding: 0 20px;
	}
}

/* mediaquery ends */
