.exporderreview h6 {
	color: #000;
	font-size: 18px;
	margin: 15px 0;
}
.reservation-detail {
	display: flex;
	margin-top: 10px;
	align-items: center;
	flex-wrap: wrap;
}
.reservation-detail .breakdown-wrapper {
	flex: 1;
}
.reservation-detail .breakdown-wrapper p {
	margin-left: 15px;
	line-height: normal;
	display: inline-block;
}
.orderreviewsummary h4 {
	padding-left: 15px;
	margin-bottom: 15px;
}
.orderreviewedit a {
	font-size: 16px;
	color: #000;
	font-weight: 600;
}
.orderreviewedit .left-angle {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
.orderreviewedit {
	padding: 0 100px;
}
/* mediaquery css starts */
@media (max-width: 1366px) {
	.orderreviewedit a {
		padding-left: 20px;
	}
}
@media (max-width: 1280px) {
	.orderreviewedit a {
		padding-left: 80px;
	}
}
@media (max-width: 1024px) {
	.orderreviewedit {
		padding: 0;
	}
	.orderreviewedit a {
		padding-left: 40px;
	}
}
@media (max-width: 767px) {
	.orderreviewsummary {
		margin-top: 30px;
	}
	.orderreviewsummary h4 {
		padding-left: 0;
	}
	.orderreviewedit a {
		padding-left: 20px;
	}
	button.cmn-btn.no-print.send-order.btn.btn-primary {
		margin: 10px auto;
	}
}
/* mediaquery css ends */
