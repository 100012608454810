.order-list-box {
	display: flex;
	padding-bottom: 30px;
	border-bottom: 1px solid #ded3d3;
	margin-bottom: 30px;
}
.order-list-box:last-child {
	padding-bottom: 0px;
	border-bottom: 0px;
	margin-bottom: 0px;
}
.order-list-box .col-sm-6,
.order-list-box .col-md-6 {
	padding-left: 0px;
}

.order-list-box .card-title {
	margin-top: 5px;
}
.order-text {
	color: #777;
}
.order-text .list-group {
	flex-direction: row;
}
.order-text .list-group .list-group-item {
	border: none;
	padding: 0.35rem 1.25rem;
}
.order-text .list-group .list-group-item:first-child {
	padding-left: 0px;
	margin-top: 0px;
}
.order-text .bookinfinfo.list-group .list-group-item {
	color: #000;
	border-right: 1px solid #d9d9d9;
	margin-top: 15px;
}
.order-text .bookinfinfo.list-group .list-group-item:last-child {
	border-right: none;
}
.exp-infor {
	display: flex;
	flex-wrap: wrap;
}
.exp-infor .icon-wrapper {
	align-self: flex-start;
}
.exp-infor .breakdown-wrapper {
	flex: 1 1;
}
.user-details {
	padding: 3px;
	padding-top: 10px;
	justify-content: space-between;
}
.user-details p {
	line-height: 34px;
	padding-bottom: 6px;
}
.user-details svg {
	color: #000;
	margin-right: 15px;
	margin-top: -5px;
}

.details-total b {
	min-width: 150px;
}

.details-total b:nth-child(2)::before {
	content: "";
	position: absolute;
	height: 25px;
	width: 1px;
	background: #d9d9d9;
	margin-left: -20px;
	margin-top: 3px;
}

.NoOrderToShow {
	margin: auto;
}

/* Styling for dropdown */
.searchfilter.customDropdown .labelText {
	color: #999;
}
.searchfilter.customDropdown .icon-angle-down.dropdown {
	margin-right: 0;
}
.searchfilter.customDropdown .icon-angle-down .dropdown-toggle {
	margin: 0.2rem 0;
	height: 3rem;
	padding: 0.5rem 1rem;
}
.searchfilter.customDropdown .icon-angle-down .btn-primary,
.searchfilter.customDropdown .icon-angle-down.show .btn-primary {
	border: 1px solid #f15b3b;
	color: #f15b3b;
}
.searchfilter.customDropdown .icon-angle-down .btn-primary:hover,
.searchfilter.customDropdown .icon-angle-down.show .btn-primary:hover {
	color: #f15b3b;
	border: 1px solid #f15b3b;
}
.searchfilter.customDropdown
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled).active,
.searchfilter.customDropdown
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled):active,
.searchfilter.customDropdown
	.icon-angle-down
	.show
	> .btn-primary.dropdown-toggle {
	color: #f15b3b;
	background-color: #d9d9d9;
	border: 1px solid #f15b3b;
}
.searchfilter.customDropdown .icon-angle-down .dropdown-item {
	color: #444;
}
.order-status-info .list-group-item {
	display: flex;
}
.order-status-info .status-val {
	margin-left: 1rem;
	color: #000;
	font-weight: 600;
}
.order-status-info .status-subval {
	margin-left: 0.5rem;
	font-weight: 400;
	color: #f15b3b;
}
.action-wrapper {
	display: flex;
}
.action-wrapper .cmn-btn.card-link {
	display: inline-block;
	margin: 0;
}
.action-wrapper .cmn-btn-inverse.btn.btn-primary {
	margin-left: auto;
}
.d-flex.justify-content-end.mb-3.col-sm-12 {
	justify-content: center !important;
}
/* mediaquery css starts */
@media (max-width: 1444px) {
	.order-list-box .card-title {
		font-size: 24px;
	}
}
@media (max-width: 1024px) {
	.user-details {
		display: block !important;
	}
	.order-list-box .card-title {
		font-size: 20px;
	}
	.order-text .list-group .list-group-item {
		font-size: 13px;
	}
}
@media (max-width: 768px) {
	.order-list-box {
		display: inline;
		padding: 30px 0px;
		margin: 0;
	}
	.order-list-box:first-child {
		padding: 0px 0px 20px 0px;
	}
	.order-list-box .col-md-5,
	.order-list-box .col-md-7 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
	}
	.order-text {
		padding: 20px;
	}

	.order-list-box img {
		width: 100%;
	}
	.order-text {
		margin-top: 20px;
	}
	.order-text .list-group .list-group-item {
		font-size: 16px;
	}
}
@media (max-width: 767px) {
	.order-text .card-title.h5 {
		font-size: 24px;
	}
	.order-text .list-group .list-group-item {
		border: none;
		padding: 8px 0px;
	}
	.order-text .list-group {
		display: block;
	}
	.order-text .bookinfinfo.list-group .list-group-item {
		border-bottom: 1px solid #d9d9d9;
		margin-top: 0;
	}
	.order-text .list-group .list-group-item {
		font-size: 14px;
	}
	.d-flex.justify-content-end.mb-3.col-sm-12 {
		justify-content: center !important;
	}
	.icon-angle-down .btn-primary {
		min-width: 160px;
	}
}

/* mediaquery css ends */
