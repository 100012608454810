.filtercont .searchfilter {
	padding: 54px 0px;
}
.filtercont {
	background: #f6f6f6;
}
.filtercont .form-control {
	font-size: 16px;
	border: none;
	width: 100%;
	display: inline-block;
}
.filtercont .formcont {
	width: 100%;
	margin: 0 auto;
}
.filtercont .submit-btn {
	display: inline-block;
	background: #f15b3b;
	border: 1px solid transparent;
	color: #fff;
	border-radius: 0rem;
	width: 160px;
	line-height: 0px;
	margin-top: 20px;
	text-transform: inherit;
}
.filtercont .submit-btn:hover {
	color: #f15b3b;
	border: 1px solid #f15b3b;
	background: #fff;
}
.form-control {
	font-size: 16px;
	color: #000;
}
.icon-angle-down .dropdown-toggle::after {
	-webkit-appearance: none;
	-moz-appearance: window;
	padding: 2px 50px 2px 2px;
	border: none;
	background: url("../../images/angle-down.png");
	background-repeat: no-repeat;
	background-position: right center;
	display: initial;
	vertical-align: middle;
	position: absolute;
	z-index: 2;
	right: 20px;
	width: 20px;
	height: 20px;
	top: 50%;
	transform: translateY(-50%);
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active,
.icon-angle-down .show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #fff;
	border-color: transparent;
	border: 0;
	border-radius: 0;
}
.icon-angle-down .btn-primary {
	color: #000;
	background: #fff;
	border: 0;
	border-radius: 0;
	padding: 15px;
	font-size: 16px;
	margin: 20px 0;
	min-width: 200px;
	text-align: left;
	height: 60px;
}
.icon-angle-down .btn-primary:hover {
	background-color: #fff;
	color: #000;
	border: 0;
	border-color: transparent;
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active,
.icon-angle-down .show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #d9d9d9;
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active:focus,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active:focus,
.icon-angle-down .show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #fff;
	border-color: #d9d9d9;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.icon-angle-down .dropdown-menu {
	width: 100%;
	background: #fff;
	border: 0;
	border-radius: 0;
}
.icon-angle-down .dropdown-item:focus,
.icon-angle-down .dropdown-item:hover {
	color: #000;
	background-color: #d9d9d9;
}
.icon-angle-down .dropdown-item.active,
.icon-angle-down .dropdown-item:active {
	color: #000;
	text-decoration: none;
	background-color: #d9d9d9;
}
.icon-angle-down .dropdown-item {
	color: #000;
}
.icon-angle-down.dropdown {
	margin-right: 10px;
	position: relative;
}
.dropdown-menu.show {
	max-height: 200px;
	overflow-y: scroll;
	border: 1px solid #d9d9d9;
}
.profile-dropdown.icon-angle-down .dropdown-menu.show {
	max-height: 325px !important;
	overflow-y: scroll;
	border: 1px solid #d9d9d9;
}
.no-scroll .dropdown-menu.show {
	overflow-y: auto;
}
/* mediaquery css starts */
@media (max-width: 1024px) {
	.filtercont .formcont {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.form-row {
		display: block;
		padding: 0 30px;
	}
	.filtercont .form-group {
		margin-bottom: 20px;
	}
	.filtercont .submit-btn {
		margin: 0 auto;
		display: block;
		width: 100%;
	}
	.icon-angle-down.dropdown {
		background: #fff;
		margin: 10px 0;
	}
	.icon-angle-down .btn-primary {
		margin: 0;
		height: auto;
	}
}
@media (max-width: 767px) {
	.filtercont .form-group {
		margin-top: 0;
	}
	.filtercont .form-control {
		width: 90%;
	}
}

/* medaiquery css ends */

.warn_msg {
	color: #f15b3b;
	font-size: 14px;
	margin-top: -10px;
}
