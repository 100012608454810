.contact-detail a {
	font-size: 16px;
	display: block;
	color: #000;
	text-align: left;
}
.contact-icons {
	margin-right: 15px;
}
.ContactUs {
	display: flex;
	width: 100%;
}
.contact-detail {
	width: 50%;
}
.contact-detail p {
	font-size: 16px;
	text-align: left;
	color: #000;
}
.text-center.message-box.ContactUs.col-sm-12 {
	padding: 50px 200px;
	margin-bottom: 150px;
}

/* mediaquery css starts */
@media (max-width: 1024px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px 100px;
	}
}
@media (max-width: 768px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px;
	}
}
@media (max-width: 767px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px;
	}
	.ContactUs {
		display: block;
	}
	.contact-detail {
		width: 100%;
	}
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 20px;
	}
	.contact-detail a {
		margin-bottom: 10px;
	}
}
/* mediaquery css ends */
