.about-text p {
	font-size: 16px;
	color: #000;
	margin-bottom: 20px;
}

.about-text h3 {
	margin: 10px 0px;
	font-size: 22px;
}
