#expdetailgallery {
	padding: 80px 50px;
}
#expdetailgallery h2 {
	text-align: left;
	padding: 0;
	padding-bottom: 30px;
}
#expdetailgallery h2::after {
	content: none;
}
#expdetailgallery .sync-slick img {
	width: 100%;
}
#expdetailgallery .slick-slider.nav-slick.slick-initialized {
	width: 50%;
	margin: 0 auto;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	z-index: 2;
}
.ril__image {
	width: 60%;
}
.ReactModal__Content.ReactModal__Content--after-open {
	top: 110px !important;
}
#expdetailgallery .sync-slick {
	position: relative;
	z-index: 1;
}
#expdetailgallery .slick-prev:before {
	content: "";
	background: url(../../images/gallery-prev.jpg) no-repeat;
	width: 51px;
	height: 45px;
	display: block;
}
#expdetailgallery .slick-next:before {
	content: "";
	background: url(../../images/gallery-next.jpg) no-repeat;
	width: 51px;
	height: 45px;
	display: block;
}
#expdetailgallery .slick-next {
	right: 0;
	top: 50%;
	bottom: 0;
	transform: translateY(-50%);
	width: 51px;
	height: 45px;
}
#expdetailgallery .slick-prev {
	left: 0;
	top: 50%;
	bottom: 0;
	transform: translateY(-50%);
	z-index: 2;
	width: 51px;
	height: 45px;
}
.nav-slick div img {
	margin: 15px;
	width: 90%;
}
.gallery .slick-slider button {
	display: none !important;
}
.gallery:hover .slick-slider button {
	display: block !important;
	transition: 0.4s all ease;
}
.nav-slick .slick-slide.slick-active {
	opacity: 0.5;
}
.nav-slick .slick-slide.slick-active.slick-center.slick-current {
	opacity: 1;
}
#expdetailgallery .slick-slider.nav-slick button {
	display: none !important;
}
.react-fancybox .box {
	z-index: 99;
}
/* mediaquery css starts */

@media (max-width: 1440px) {
	.nav-slick div img {
		width: 95%;
	}
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		width: 70%;
	}
	#expdetailgallery h2 {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 1280px) {
	.nav-slick div img {
		width: 95%;
	}
}
@media (max-width: 1024px) {
	#expdetailgallery h2 {
		font-size: 38px;
		line-height: 50px;
	}
}
@media (max-width: 768px) {
	.nav-slick .slick-track {
		margin: 0 auto;
	}
	nav.f-social.navbar.navbar-expand.navbar-light a {
		padding: 0 10px;
	}
}
@media (max-width: 767px) {
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		position: static;
	}

	#expdetailgallery {
		padding: 50px 30px 0;
	}
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		width: 100%;
	}
	.nav-slick div img {
		width: 90%;
		margin: 0 auto;
	}
	.gallery .slick-slider button {
		display: block !important;
	}
	#expdetailgallery .sync-slick img {
		height: 18vh;
		object-fit: cover;
	}
}
/* mediaquery css ends */
