#newsletter {
	width: 70%;
	margin: 0 auto;
	text-align: center;
}

label.heading {
	font-size: 60px;
	text-align: center;
	line-height: 62px;
	text-transform: none;
	margin-bottom: 15px;
	margin: 50px 0 100px 0;
	position: relative;
	font-family: "Chronicle Text G1";
}
label.heading:before {
	content: "";
	width: 97px;
	height: 4px;
	background: #f15b3b;
	display: block;
	margin: 0 auto;
	bottom: -25px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}
#newsletter .form-control {
	width: 70%;
	float: left;
	line-height: 60px;
	font-size: 20px;
	height: 60px;
	border: 1px solid #f15b3b;
	border-radius: 0;
}
#newsletter .submit-btn {
	width: 30%;
	height: 60px;
	font-size: 20px;
	line-height: 50px;
	background: #f15b3b;
	color: #fff;
	text-transform: none;
	border-radius: 0;
	border: 1px solid transparent;
}
#newsletter .form-text {
	position: absolute;
	width: auto;
	top: 110px;
	color: #f15b3b;
}
#newsletter .suc_text {
	color: #48ac15;
}
#newsletter .err_text {
	color: #f15b3b;
}
.form-group {
	margin-bottom: 0rem;
}

nav.f-social.navbar.navbar-expand.navbar-light .navbar-nav .nav-link {
	padding: 15px 18px;
}

/* meadiaquery starts */
@media (max-width: 1024px) {
	#newsletter {
		width: 50%;
	}
	#newsletter .form-control,
	#newsletter .submit-btn {
		height: 70px;
	}
}
@media (max-width: 767px) {
	.section-06 h2.heading {
		margin-bottom: 0;
	}
	#newsletter .form-group input::-webkit-input-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input:-ms-input-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input::placeholder {
		text-align: center;
		color: #000;
	}
}
/* mediaquery ends */
