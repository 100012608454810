.galleryalbum img {
	width: 100%;
}

.galleryalbum .slick-prev {
	left: 0px;
	z-index: 1;
	background: white;
	width: 50px;
	height: 30px;
}
.galleryalbum .slick-next {
	right: 0;
	background: white;
	width: 50px;
	height: 30px;
}
.galleryalbum .slick-next:before,
.galleryalbum .slick-prev:before {
	font-size: 20px;
	line-height: 1;
	opacity: 1;
	color: #000;
}
.modal-content {
	padding: 10px;
}
.card-body {
	padding-left: 0px;
}
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.6);
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.expcard.gallerywrap {
		padding-bottom: 20px;
	}
}
/* mediaquery css ends */
