.faq h4,
.faq h4 a {
	font-size: 18px;
	color: #000;
}
.faq {
	border-bottom: 1px solid #ccc;
}
.faq h4 {
	margin-bottom: 30px;
}
.faqaccordian {
	margin: 80px 0;
}
.faqaccordian .card {
	margin-top: 30px;
}
.faqaccordian .card-header,
.faqaccordian .card-header a {
	font-size: 18px;
	color: #000;
	background-color: transparent;
	border-bottom: 0;
	padding: 0.75rem 0;
}
.faqaccordian .card-body p,
.faqaccordian .card-body li {
	font-size: 16px;
	margin-bottom: 20px;
	color: #777;
}
.faqaccordian .accordion > .card {
	border-bottom: 1px solid #ccc !important;
}
.faqaccordian .accordion > .card .card-header {
	margin-bottom: 10px;
}
.account-qstn.faqaccordian {
	margin: 0;
}
.account-qstn.faqaccordian .card-body p {
	margin-bottom: 0;
}
.account-qstn.faqaccordian .card {
	margin-top: 20px;
}
.account-qstn.faqaccordian.refund {
	margin-top: 50px;
}
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.faqaccordian.ac-qstn .card-header > a:before {
	float: right !important;
	font-family: FontAwesome;
	content: "\f067";
	padding-right: 5px;
	font-size: 16px;
}
.faqaccordian.ac-qstn .collapse.show::before {
	position: absolute;
	height: 40px;
	width: 40px;
	content: "";
	font-family: FontAwesome;
	float: right !important;
	content: "\f068";
	top: 25px;
	right: -22px;
	background: #fff;
}

.faqaccordian .card-header a {
	display: block;
}
/* media query starts */
@media (max-width: 768px) {
	.faqaccordian .card-header,
	.faqaccordian .card-header a,
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		font-size: 20px;
	}
	.faqaccordian .card-header {
		padding: 0.75rem 1.25rem;
	}
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		padding: 0 1.25rem;
	}
	.faqaccordian.ac-qstn .collapse.show::before {
		right: 0;
	}
}
@media (max-width: 767px) {
	.faq h4,
	.faq h4 a {
		font-size: 20px;
	}
	.faqaccordian h3.txt-orange {
		font-size: 22px;
	}
	.faqaccordian .card-header {
		padding: 0.75rem 0;
	}
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		padding: 0;
	}
	.faqaccordian.ac-qstn .collapse.show::before {
		right: -22px;
	}
}
/* media query ends */
