.multicheckbox {
	display: inline-block;
	margin-right: 30px;
}
.plan-experience .message-box label {
	margin-bottom: 10px;
	display: block;
}
.plan-experience .icon-angle-down {
	border: 1px solid #c6c6c6;
	width: 30%;
}
.plan-experience .icon-angle-down .btn-primary {
	height: auto;
	margin: 0;
	padding: 10px;
}
.planexperienceform {
	margin-top: 20px;
}
.plan-experience
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled).active,
.plan-experience
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled):active,
.plan-experience .icon-angle-down .show > .btn-primary.dropdown-toggle {
	background: transparent;
}
.plan-experience .icon-angle-down .btn-primary::before {
	content: "*";
	font-size: 18px;
	position: relative;
	top: 4px;
	left: 0;
	line-height: 0;
	margin-right: 5px;
	color: #c6c6c6;
	font-family: serif;
}
.plan-experience .form-group {
	margin: 15px 0 25px;
}
/* .plan-experience .form-group .errorMsg {
	position: absolute;
} */
.plan-experience .message-box .nospace {
	margin-bottom: 0;
}
.planexperienceform label.form-check-label {
	color: #908282;
	margin-bottom: 0;
	position: relative;
	top: 2px;
}

/* mediaquery css starts */
@media (max-width: 1024px) {
	.plan-experience .icon-angle-down {
		width: 35%;
	}
}
@media (max-width: 767px) {
	.multicheckbox {
		display: block;
	}
	.plan-experience .icon-angle-down {
		width: 100%;
	}
	.plan-experience .register-form .form-control {
		width: 100%;
	}
	.plan-experience .message-box {
		margin: 40px 5px;
		padding: 20px 10px;
	}
	.plan-experience .register-form .form-control::placeholder {
		font-size: 10px;
	}
	.plan-experience .mx-auto.register-form.col-md-10.col-sm-12 {
		padding: 0 10px;
	}
	/* .plan-experience   .register-form .form-row{
    margin-bottom: 45px;;
  } */
}
/* mediaquery css ends */
