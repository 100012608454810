.expdetailtopimg {
	height: 720px;
}

/* mediaquery css starts */
@media (max-width: 1440px) {
	.expdetailtopimg {
		height: 500px;
	}
}
@media (max-width: 1024px) {
	.expdetailtopimg {
		height: 340px;
	}
}
@media (max-width: 767px) {
	.expdetailtopimg {
		height: 155px;
	}
}
/* mediaquery css ends */
