.Wishlisthold span {
	font-size: 18px;
	color: #a99f9f;
	text-align: center;
	display: block;
	margin-bottom: 20px;
}

.WishListEmpty {
	margin: 100px auto 100px;
}

.expcard .card-text > span {
	text-align: left;
}