.otp-no input {
	height: 30px;
	width: 3rem;
	min-width: 50px;
	background: #fff;
	border: 0px solid #c6c6c6;
	border-bottom: 1px solid #c6c6c6;
	outline: none;
}
.otp-no span {
	height: 1px;
	width: 10px;
}
.otp-no a {
	color: #f15b3b;
	display: inline-flex;
	vertical-align: text-top;
	margin-left: 10px;
	justify-content: flex-end;
	width: 30%;
}
.otpinput {
	display: inline-block;
	vertical-align: bottom;
}

.regac {
	font-size: 18px;
	color: #000;
}
div#formOtp {
	width: 100%;
}
.regac.form-group a {
	padding-left: 10px;
}

.progress {
	width: 30%;
	height: 12px;
	border-radius: 10px;
	display: inline-flex;
}
.fieldError {
	display: inherit;
}
.errormsg {
	font-size: 16px;
	color: #000;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}
.regform .form-row {
	width: 100%;
}
.regform .otp-no a {
	width: auto;
	margin-left: 0;
	margin-top: 10px;
	display: block;
}
.message-box .regform label {
	color: #c6c6c6;
	line-height: 24px;
}
.message-box .regform label.form-check-label {
	color: #000;
}
.icon-angle-down.regdrpdwn.dropdown {
	border-bottom: 1px solid #d9d9d9;
	width: calc(100% - 30px);
	height: 82px;
}
.icon-angle-down.regdrpdwn.dropdown .btn-primary {
	margin-bottom: 0;
	color: #c6c6c6;
}
.icon-angle-down.regdrpdwn.dropdown .dropdown-toggle::after {
	width: 10px;
	height: 10px;
	top: 70%;
}
.errorMsg {
	color: red;
	display: block;
	width: 100%;
}
.avai_text {
	color: green !important;
}
img.cancel-image {
	width: 20px;
	height: 20px;
	margin-top: 25px;
}
img.check-image {
	width: 23px;
	height: 30px;
	margin-top: 10px;
	margin-left: 10px;
}
.pincodeinput.form-group.col {
	margin-top: 28px;
}
button.cmn-btn.btn.btn-primary:disabled {
	color: #fff;
	background-color: #f15b3b;
	border-color: #f15b3b;
	cursor: not-allowed;
}
button.dropdown-toggle.btn.btn-primary:disabled {
	cursor: not-allowed;
}
.form-control:disabled,
.form-control[readonly] {
	cursor: not-allowed;
}
.message-box .regform label.form-check-label:hover {
	color: #f15b3b;
	cursor: pointer;
}
.message-box .regform label.form-check-label {
	display: inline-block;
	vertical-align: middle;
}
.d-flex-phn {
	width: 100%;
	display: flex;
}
/* mediaquery css starts */

@media (max-width: 1440px) {
	.pincodeinput.form-group.col input {
		top: 12px;
	}
}
@media (max-width: 1024px) {
	.otp-no input {
		min-width: 30px;
	}
}
@media (max-width: 768px) {
	.mx-auto.register-form.col-md-10.col-sm-12 {
		flex: 100%;
		max-width: 100%;
	}
	.register-form .form-row {
		padding: 0;
	}
	.regform .d-flex {
		display: block !important;
	}
	.pincodeinput.form-group.col input,
	.pincodeinput.form-group.col .errorMsg {
		position: static;
	}
	.icon-angle-down.regdrpdwn.dropdown {
		height: auto;
	}
}
@media (max-width: 767px) {
	.otp-no input {
		min-width: 22px;
	}
	.otp-no a {
		width: 100%;
		justify-content: flex-start;
	}
	.regac {
		font-size: 16px;
	}
	.regac .txt-orange {
		margin-left: 10px;
	}
	.otp-no a {
		margin-left: 0;
		padding-top: 10px;
	}
	.message-box .regform label.form-check-label {
		display: inline-block;
		vertical-align: middle;
	}
}

/* mediaquery css ends */
