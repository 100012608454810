.date-selector input {
	border: 0;
	background: transparent;
	padding: 0 15px;
}
.sort-date {
	display: flex;
	justify-content: flex-end;
}
.date-selector {
	border: 1px solid #d9d9d9;
	position: relative;
}
.sort-date h6 {
	display: inline-block;
	vertical-align: middle;
	padding: 20px 0;
	margin-right: 20px;
}
.sort-date .DayPicker {
	width: 100%;
}
.blogsdata {
	margin: 35px 0;
}
.card.dash-box {
	border: 2px dashed #d9d9d9;
	padding: 30px;
}
.date-tite {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
}
.blog .card-title {
	font-size: 30px;
	margin: 20px 0;
}
.blog .card-text {
	margin: 0;
}
.blog {
	margin-bottom: 30px;
}
.blogcomment {
	display: flex;
	font-size: 18px;
}
.blog .card-text.blogBy {
	border-right: 1px solid #d9d9d9;
	margin-right: 10px;
	padding-right: 10px;
}
.date-tite .card-text {
	color: #8a8a8a;
}
.showmore-blog {
	margin-bottom: 150px;
}

/* mediaquery css starts */
@media (max-width: 1366px) {
	.blog .card-title {
		font-size: 24px;
	}
}
@media (max-width: 768px) {
	.col-md-4.blog {
		max-width: 100%;
		flex: 100%;
	}
	.showmore-blog {
		margin-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.sort-date {
		justify-content: center;
	}
	.blog .card-title.h5 {
		font-size: 20px;
	}
	.blogcomment {
		font-size: 16px;
	}
	.showmore-blog {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */
