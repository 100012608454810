.left-menu {
	padding-left: 160px;
}
.navbar-light .navbar-nav a {
	color: #000;
	font-size: 18px;
	padding-right: 48px;
}
.navbar-light .navbar-nav a:last-child {
	padding-right: 0;
}
.logreg {
	margin-left: 35px;
}
form#topsearch,
#topsearchphn {
	border: 1px solid #d7d7d7;
	width: 300px;
}

form#topsearch .submit-btn,
#topsearchphn .submit-btn {
	width: 20%;
	padding: 0px 20px 0 0;
	font-size: 20px;
	height: 50px;
	line-height: 50px;
	background: transparent;
}
form#topsearchphn {
	margin: 15px auto;
}
form#topsearch .form-control,
#topsearchphn .form-control {
	border: 0px solid #e1e1e1;
	width: 80%;
	float: left;
	height: 50px;
}
form#topsearch .invalid-feedback,
#topsearchphn .invalid-feedback {
	position: absolute;
	margin-top: 0;
	bottom: 5px;
	width: auto;
}
.btn-primary {
	border-color: 0;
}

.navbar-light .navbar-nav a:hover {
	color: #f15b3b;
}
.navbar-light .navbar-nav a.active {
	color: #f15b3b;
}
.navbar-light .navbar-nav a.loginbtna {
	padding: 45px 30px 10px;
	background: #f15b3b;
	color: #fff;
	max-height: 90px;
	margin-top: -34px;
	border: 1px solid transparent;
}
.navbar-light .navbar-nav a.loginbtna:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
	transition: 0.4s all ease;
}
.navbar {
	padding: 0;
	padding: 25px 0;
}
i.fa.fa-search:hover {
	outline: none;
	color: #f15b3b;
}
/* .navbar-light .navbar-toggler-icon {
    background-image: url("../images/download_orange.svg");
  } */

/* meadiaquery starts */
@media (max-width: 767px) {
	.container-grid.container-fluid {
		padding: 0 20px;
	}
	.rega {
		margin-left: 0;
	}
	.navbar {
		padding: 0;
	}
}
/* mediaquery ends */
