.cmn-btn.login-btn {
	margin-right: 20px;
}
.loginpage .d-flex {
	justify-content: space-between;
}
.regac.form-group a.forgotpasswordbtn {
	padding-left: 5px;
	margin-left: 0;
}
.errormsg {
	font-size: 16px;
	color: #000;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}

/* media query starts */
@media (max-width: 768px) {
	.loginpage .d-flex {
		display: block !important;
	}
}
/* media query ends */
