.loader-wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.8)
}