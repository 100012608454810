.termspolicieswrap {
	margin-bottom: 150px;
}
.termspolicies h3 {
	margin-bottom: 20px;
	font-size: 22px;
}
.termspolicies p {
	color: #000;
	margin-bottom: 10px;
	font-size: 16px;
}
.top-space {
	margin-top: 20px;
}
.termspolicies ul {
	list-style-type: none;
}
.termspolicies ul li {
	margin-left: 30px;
	margin-bottom: 10px;
}
.termspolicies ul li::before {
	content: "\2022";
	color: #f15b3b;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}
/* mediaquery css starts */
@media (max-width: 767px) {
	.termspolicies p,
	.termspolicies ul li {
		font-size: 16px;
	}
	.termspolicieswrap {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */
