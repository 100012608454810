#expdeatilstory h2::after {
	content: none;
}
#expdeatilstory h2 {
	text-align: left;
	padding: 0;
}
.storyprice {
	color: #f15b3b;
	font-size: 22px;
	margin-top: 10px;
}
#expdeatilstory {
	padding: 45px 50px 0;
	z-index: 0;
	position: relative;
}
#expdeatilstory p {
	padding: 10px 0 0;
	color: #808080;
	line-height: 24px;
	font-size: 14px;
	padding-right: 50px;
}
.heart-icon {
	margin-right: 15px;
}
.heart-icon:hover,
.share-icon:hover {
	color: #f15b3b;
}
.expdetail-social-icon.col-sm-1 {
	padding: 30px 0;
	text-align: left;
}
.expdetailstorybookwrap {
	display: flex;
	justify-content: flex-end;
}
span.expdetail-social-icon {
	border-left: 1px solid #000;
	margin: 0 15px;
	color: #000;
}
svg.svg-inline--fa.fa-heart.fa-w-16.heart-icon {
	margin-left: 15px;
}
.offerprice {
	border: 2px dashed #f15b3b;
	width: 15%;
	padding: 10px;
	background: #f6f6f6;
	margin-top: 30px;
}
#expdeatilstory .offerprice p {
	padding: 0;
	color: #000;
}
.offerprice h3 {
	font-weight: 900;
	font-size: 20px;
}
.date-input span.err_text {
	display: block;
	width: 100%;
}
/* .section-06.container {
	z-index: -1;
	position: relative;
}
.section-07.footer-address.container-grid.container-fluid {
	position: relative;
	z-index: -2;
} */
.hideindesk {
	display: none;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	#expdeatilstory h2 {
		font-size: 50px;
		line-height: 72px;
	}
	.offerprice {
		width: 20%;
	}
	.wrap-detail.col-sm-8 {
		margin-top: -150px;
	}
	/* .ril__image {
		transform: translate3d(0px, 0px, 0px) scale3d(0.824623, 0.824623, 1) !important;
	} */
	img.ril__image {
		width: auto;
	}
}
@media (max-width: 1366px) {
	.calender-icon {
		margin-top: 18px;
	}
	.booking .form-control {
		padding: 6px 0;
	}
	.booking .selectcontrol.form-group.col {
		padding: 6px 12px;
	}
	input.choosedate {
		padding-left: 12px;
	}
	.calender-icon {
		margin-right: 12px;
	}
	.offerprice {
		width: 22%;
	}
}
@media (max-width: 1280px) {
	#expdeatilstory h2 {
		font-size: 48px;
	}
	.offerprice {
		width: 26%;
	}
}
@media (max-width: 1024px) {
	#expdeatilstory h2 {
		font-size: 34px;
		line-height: 46px;
	}
	.storyprice {
		font-size: 20px;
	}
	#expdeatilstory p {
		padding-right: 20px;
	}
	.offerprice {
		width: 28%;
	}
	input.choosedate,
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
	#expdeatilstory .col-sm-7 {
		flex: 100%;
		max-width: 100%;
	}
	#expdeatilstory .col-sm-4,
	.expdetail-social-icon.col-sm-1 {
		flex: 100%;
		max-width: 100%;
	}
	.expdetail-social-icon.col-sm-1 {
		padding: 30px 20px;
	}
	.booking .selectcontrol.form-group.col {
		padding: 6px 20px;
	}
	.offerprice {
		width: 38%;
	}
	.wrap-detail.col-sm-8 {
		flex: 100%;
		max-width: 100%;
	}
	img.ril__image {
		width: auto;
	}
	.expdeatilstorywrap .wrap-detail.col-sm-8 {
		margin-top: 0;
	}
	.hideindesk {
		display: block;
	}
	.cancel-wrapper .cmn-btn-inverse.btn.btn-primary {
		height: 50px;
	}
}

@media (max-width: 767px) {
	#expdeatilstory h2 {
		font-size: 32px;
		line-height: 40px;
	}
	#expdeatilstory p {
		padding-right: 0;
	}
	.expdetail-social-icon.col-sm-1 {
		padding: 0 20px 20px;
	}
	#expdeatilstory {
		padding: 45px 30px 0;
	}
	.offerprice {
		width: 53%;
	}
	#expdeatilstory .offerprice h2 {
		text-align: center;
	}
}
/* mediaquery css ends */
