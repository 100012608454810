.Newsdata {
	margin: 35px 0;
}
.News.col-md-3.col-12 {
	border-bottom: 1px solid #d9d9d9;
	margin-bottom: 50px;
	padding-bottom: 30px;
}
.showmore-News {
	margin-bottom: 150px;
}
.newstitle.card-text {
	font-size: 28px;
	line-height: 34px;
	margin-bottom: 5px;
}
.newsdate.card-text {
	color: #8a8a8a;
}
.brief.card-text {
	font-size: 18px;
	color: #8a8a8a;
	margin-top: 20px;
}

/* mediaquery css starts */
@media (max-width: 1440px) {
	.newstitle.card-text {
		font-size: 24px;
		line-height: 30px;
	}
}
@media (max-width: 768px) {
	.News.col-md-3.col-12 {
		flex: 100%;
		max-width: 100%;
	}
	.showmore-News {
		margin-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.newstitle.card-text {
		font-size: 22px;
	}
	.News.col-md-3.col-12 {
		margin-bottom: 35px;
		padding-bottom: 20px;
	}
	.showmore-News {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */
