.regthankyou h2 {
	padding: 0;
}

@media (max-width: 768px) {
	.message-box {
		margin: 40px 20px;
		padding: 20px 20px;
	}
	.regthankyou h3 {
		font-size: 20px;
	}
}
