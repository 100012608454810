.icon-angle-down.profile-dropdown.dropdown {
	background: #f15b3b;
	margin-top: -60px;
	margin-right: 0;
}
.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary {
	background: #f15b3b;
	color: #fff;
	height: 60px;
	margin: 0;
	margin-top: 50px;
	padding-left: 20px;
	padding-right: 50px;
}
.profile-dropdown.dropdown.icon-angle-down .dropdown-toggle::after {
	background: url(../../images/angle-white.png) no-repeat;
	right: -20px;
	background-position: right center;
	position: relative;
}
.profile-dropdown.icon-angle-down .dropdown-menu {
	background: #fff;
	border: 1px solid #f15b3b;
}
.navbar-light .navbar-nav .profile-dropdown .dropdown-menu a:first-child {
	padding-left: 25px;
}
.profile-dropdown a.dropdown-item {
	border-bottom: 1px solid #d1d1d1;
	width: 80%;
	margin: 0 auto;
}
.profile-dropdown a.dropdown-item:last-child {
	border-bottom: 0;
}
.profile-dropdown.icon-angle-down .dropdown-item.active,
.profile-dropdown.icon-angle-down .dropdown-item:active {
	background-color: transparent;
}
/* .profile-dropdown:hover .dropdown-menu {
	display: block;
} */

/* mediaquery css starts */
@media (max-width: 768px) {
	.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary,
	.icon-angle-down.profile-dropdown.dropdown {
		margin-top: 0;
	}
	.icon-angle-down.profile-dropdown.dropdown {
		background: transparent;
		margin: 0 auto;
	}
}
@media (max-width: 767px) {
	.icon-angle-down.profile-dropdown.dropdown,
	.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary {
		margin-top: 0;
	}
	.profile-dropdown.dropdown.icon-angle-down .dropdown-toggle:after {
		top: 60%;
		background-position: right center;
	}
}
/* mediaquery css ends */

/* Global styles */
.navbar-light .navbar-nav .profile-dropdown .dropdown-menu a.dropdown-item {
	padding-left: 20px;
}
.navbar-light
	.navbar-nav
	.profile-dropdown
	.dropdown-menu
	a.dropdown-item:hover {
	color: #000;
}
button:focus {
	outline: 0;
	outline: 0;
}
