select#formGridguest,
select#formGriddate {
	height: 50px;
}
.share-icons .fa {
	margin-left: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-top: 15px;
}
.booking .form-row {
	background: #f6f6f6;
	padding: 30px;
}
.date-input.form-group.col {
	display: flex;
	border: 1px solid #d9d9d9;
	background: #fff;
	width: 50%;
	max-height: 60px;
	margin-top: 20px;
}
.booking label {
	font-size: 22px;
	text-align: left;
	color: #000;
	display: block;
	width: 100%;
}
.booking .form-control {
	background-color: transparent;
	background-image: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: #000;
}
.booking .selectcontrol.form-group.col {
	padding-left: 0;
	padding-left: 0;
	display: flex;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #e1e1e1;
	border-radius: 0;
	box-shadow: none;
	color: #000;
	font-size: 16px;
	height: 60px;
	padding: 6px 25px;
	margin-right: 10px;
}
.booking .arrow-down {
	margin-top: 8px;
}
.booking select#formGridguest {
	-webkit-appearance: none;
}
button.pre-book-btn.btn.btn-primary {
	display: block;
	width: 100%;
	border: 1px solid transparent;
	padding: 10px 30px;
	margin: 0;
	display: table;
	background: #f15b3b;
	color: #fff;
}
button.pre-book-btn.btn.btn-primary:hover {
	background: #fff;
	color: #f15b3b;
	border: 1px solid #f15b3b;
}
button.pre-book-btn.btn.btn-primary:disabled,
button.pre-book-btn.btn.btn-primary:hover:disabled {
	background: #c2b7b7;
	color: #fff;
	border: 1px solid #c2b7b7;
}
input.choosedate {
	border: 0;
	background: transparent;
	width: 90%;
	padding-left: 20px;
}
.calender-icon {
	margin-top: 18px;
	margin-right: 20px;
}
input:focus {
	outline: none;
}
.date-input input::-webkit-input-placeholder {
	color: #000;
}

.date-input input:-ms-input-placeholder {
	color: #000;
}

.date-input input::placeholder {
	color: #000;
}
.DayPicker {
	position: absolute;
	background: #fff;
	right: 0;
	top: 60px;
	border: 1px solid #d9d9d9;
	z-index: 2;
	width: auto;
}
.DayPicker-Month {
	width: 100%;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #f15b3b;
}
.DayPicker-Day {
	border-radius: 0;
}
.DayPicker-Day--disabled {
	color: #d9d9d9;
	position: relative;
}
.DayPicker-Day--disabled::after {
	content: "";
	position: absolute;
	width: 20px;
	height: 1px;
	background: #d9d9d9;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
}
.note {
	font-size: 16px;
	color: #000;
	line-height: 26px;
	padding-top: 10px;
	padding-bottom: 20px;
}
.note .txt-orange {
	font-weight: 500;
}
.modal-title {
	font-size: 40px;
	color: #000;
}
.modal-content {
	padding: 0 50px 50px;
}
.modal-header {
	border-bottom: 0;
}
.modal-body h4 {
	font-size: 25px;
	color: #000;
}
.modal-footer {
	border-top: 0;
	justify-content: flex-start;
}
.modal-body .form-group {
	margin: 0;
}
.modal-body .form-control {
	border: 0;
	font-size: 18px;
	border-bottom: 1px solid #d9d9d9;
	padding: 6px 5px;
}
.close {
	opacity: 1;
	font-size: 30px;
}
button.pre-book-btn.border-btn.btn.btn-primary {
	margin-top: 15px;
}
.booking .err_text {
	display: block;
	width: 100%;
	color: #f15b3b !important;
	font-size: 16px;
}
.booking .looks_like_link {
	color: #f15b3b !important;
	cursor: pointer;
	font-size: 16px;
}
.booking .looks_like_link:hover {
	text-decoration: underline;
}
form.booking.fixbookform {
	position: fixed;
	/* top: 50%;
	transform: translateY(-50%); */
	top: 15%;
	width: 30%;
	z-index: 999;
	transition: 0.4s all ease;
}
form.booking.stickbookform {
	position: fixed;
	width: 30%;
	top: 15%;
	transition: 0.4s all ease;
}
.show-mbl {
	display: none;
}
/* mediaquery css starts */
@media (min-width: 1024px) {
	.booking .form-row {
		position: sticky;
		top: 120px;
	}
	.Expdetailexpectwrap.row .col-md-8 {
		flex: 100% 1;
		max-width: 100%;
	}
}
@media (max-width: 1440px) {
	.DayPicker {
		width: auto;
	}
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		min-width: 160px;
	}
}
@media (max-width: 1024px) {
	input.choosedate {
		padding-left: 10px;
	}
	.calender-icon {
		margin-right: 10px;
	}
	.booking .form-row {
		padding: 30px 10px;
	}
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		min-width: 120px;
	}
	.booking label {
		font-size: 20px;
	}
}
@media (max-width: 768px) {
	.date-input.form-group.col {
		width: 100%;
		height: 50px;
		margin: 0 auto;
	}
	.calender-icon {
		margin-top: 15px;
	}
	input.choosedate {
		width: 100%;
	}
	.DayPicker {
		left: 0;
		width: 100%;
		top: 50px;
	}
	.modal-body .form-row {
		padding: 0;
	}
	.animatefullwidth.animated.fadeInRight {
		width: 100%;
	}
	form.booking {
		display: none;
	}
	.show-mbl {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		z-index: 3;
	}
	.displayflex.col-md-8.col-sm-8 {
		padding: 50px 0;
	}
	#expdetailgallery {
		position: relative;
		z-index: 0;
	}

	form.reserve-form.booking {
		display: block;
	}
	.wrap-detail.col-sm-8 {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.modal-content {
		padding: 0 15px 20px;
	}
	.modal-title {
		font-size: 30px;
	}
	.close {
		font-size: 22px;
	}
	.modal-body h4 {
		font-size: 20px;
	}
	form.booking {
		margin-top: 50px;
	}
	#expdetailgallery .gallery:hover .slick-slider button {
		display: block !important;
	}
	.reserve-popup form.booking {
		margin-top: 0;
	}
	.reserve-popup .booking label {
		padding: 5px 5px;
	}
	.reserve-popup .icon-angle-down .btn-primary {
		min-width: initial;
		width: 100%;
		border: 1px solid #ccc;
	}
	.reserve-popup .DayPicker-Day {
		padding: 0.1em;
	}
}
/* mediaquery css ends */

.booking .dropdown.in-valid .dropdown-toggle {
	border: 1px solid #f15b3b;
}
.booking .date-input.in-valid {
	border: 1px solid #f15b3b;
}
.booking .invalid-dates {
	font-size: 16px;
	color: #f15b3b;
}
