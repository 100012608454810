.section-01 .text-holder {
	transform: translateY(60%);
	text-align: center;
}
.section-01 h2 {
	color: #fff;
	font-size: 40px;
	font-weight: 400;
	text-transform: none;
	padding-bottom: 30px;
}

.section-01 h2 span {
	font-size: 70px;
	color: #fff;
	display: block;
}

.section-01 {
	height: 550px;
}
.text-holder h2::after {
	content: none;
}

/* meadiaquery starts */
@media (max-width: 1440px) {
	.section-01 img.react-parallax-bgimage {
		height: auto !important;
		width: auto !important;
	}
}
@media (max-width: 1024px) {
	.section-01 h2 span {
		font-size: 60px;
	}
	.section-01 h2 {
		font-size: 36px;
	}
}
@media (max-width: 767px) {
	.section-01 h2 span {
		font-size: 56px;
	}
	.section-01 h2 {
		font-size: 30px;
		line-height: 40px;
	}
}
/* mediaquery ends */
