@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.left-menu {
	padding-left: 160px;
}
.navbar-light .navbar-nav a {
	color: #000;
	font-size: 18px;
	padding-right: 48px;
}
.navbar-light .navbar-nav a:last-child {
	padding-right: 0;
}
.logreg {
	margin-left: 35px;
}
form#topsearch,
#topsearchphn {
	border: 1px solid #d7d7d7;
	width: 300px;
}

form#topsearch .submit-btn,
#topsearchphn .submit-btn {
	width: 20%;
	padding: 0px 20px 0 0;
	font-size: 20px;
	height: 50px;
	line-height: 50px;
	background: transparent;
}
form#topsearchphn {
	margin: 15px auto;
}
form#topsearch .form-control,
#topsearchphn .form-control {
	border: 0px solid #e1e1e1;
	width: 80%;
	float: left;
	height: 50px;
}
form#topsearch .invalid-feedback,
#topsearchphn .invalid-feedback {
	position: absolute;
	margin-top: 0;
	bottom: 5px;
	width: auto;
}
.btn-primary {
	border-color: 0;
}

.navbar-light .navbar-nav a:hover {
	color: #f15b3b;
}
.navbar-light .navbar-nav a.active {
	color: #f15b3b;
}
.navbar-light .navbar-nav a.loginbtna {
	padding: 45px 30px 10px;
	background: #f15b3b;
	color: #fff;
	max-height: 90px;
	margin-top: -34px;
	border: 1px solid transparent;
}
.navbar-light .navbar-nav a.loginbtna:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}
.navbar {
	padding: 0;
	padding: 25px 0;
}
i.fa.fa-search:hover {
	outline: none;
	color: #f15b3b;
}
/* .navbar-light .navbar-toggler-icon {
    background-image: url("../images/download_orange.svg");
  } */

/* meadiaquery starts */
@media (max-width: 767px) {
	.container-grid.container-fluid {
		padding: 0 20px;
	}
	.rega {
		margin-left: 0;
	}
	.navbar {
		padding: 0;
	}
}
/* mediaquery ends */

.section-01 .text-holder {
	-webkit-transform: translateY(60%);
	        transform: translateY(60%);
	text-align: center;
}
.section-01 h2 {
	color: #fff;
	font-size: 40px;
	font-weight: 400;
	text-transform: none;
	padding-bottom: 30px;
}

.section-01 h2 span {
	font-size: 70px;
	color: #fff;
	display: block;
}

.section-01 {
	height: 550px;
}
.text-holder h2::after {
	content: none;
}

/* meadiaquery starts */
@media (max-width: 1440px) {
	.section-01 img.react-parallax-bgimage {
		height: auto !important;
		width: auto !important;
	}
}
@media (max-width: 1024px) {
	.section-01 h2 span {
		font-size: 60px;
	}
	.section-01 h2 {
		font-size: 36px;
	}
}
@media (max-width: 767px) {
	.section-01 h2 span {
		font-size: 56px;
	}
	.section-01 h2 {
		font-size: 30px;
		line-height: 40px;
	}
}
/* mediaquery ends */

#home-journey {
	margin-bottom: 50px;
}

#home-journey .part1 {
	margin-left: 0;
}

#home-journey .part1,
#home-journey .part2,
#home-journey .part3,
#home-journey .part {
	width: 100%;
	padding: 0;
}

#home-journey h3 {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	color: #fff;
	font-size: 40px;
	font-family: "Chronicle Text G1";
}

#home-journey img {
	width: 100%;
}

#home-journey .item {
	position: relative;
}

#home-journey .item .overlay {
	cursor: pointer;
}

#home-journey .item img {
	overflow: hidden;
	width: 100%;
}

#home-journey .item span {
	opacity: 0;
	background-color: #f15b3b;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}

#home-journey .item:hover span {
	opacity: 1;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}

#home-journey .item nav.Link {
	display: block;
	overflow: hidden;
}

#home-journey .item span {
	text-align: left;
	padding: 30px 30px;
}

#home-journey .item span p {
	color: #fff;
	font-size: 14px;
	line-height: 22px;
}

#home-journey .item button:focus {
	outline: none;
}

#home-journey .item .nav-link {
	padding: 0;
}
#home-journey .slick-slide div {
	margin: 15px;
}
#home-journey .slick-dots li button:before {
	color: #f15b3b;
}
/* meadiaquery starts */

@media (max-width: 1280px) {
	#home-journey {
		margin-bottom: 0;
	}
	h2 {
		font-size: 60px;
		line-height: 65px;
	}
}

@media (max-width: 768px) {
	#home-journey .row {
		display: block;
	}
	#home-journey .part1,
	#home-journey .part2,
	#home-journey .part3,
	#home-journey .part {
		margin: 0 auto;
		margin-bottom: 30px;
		width: 85%;
	}
	#home-journey h3 {
		font-size: 70px;
	}
	#home-journey .item span p {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	#home-journey h3 {
		font-size: 45px;
	}
	#home-journey .item span p {
		font-size: 18px;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		padding: 0px 20px;
	}
	#home-journey .slick-dots {
		bottom: auto;
	}
	#home-journey .slick-dots li {
		margin: 0;
	}
}

/* mediaquery ends */

.featuredexp {
	padding: 0 30px;
}
.featuredexphold {
	margin-bottom: 100px;
	margin-top: 20px;
}
a.show-more {
	color: #f15b3b;
	text-decoration: none;
	border: 1px solid #f15b3b;
	border-radius: 0;
	padding: 10px 30px;
	font-size: 16px;
	font-weight: 600;
}
.arrorright {
	font-size: 16px;
	margin-left: 10px;
	color: #f15b3b;
	display: inline-block;
	vertical-align: middle;
}
a.show-more:hover {
	color: #fff;
	background: #f15b3b;
}
a.show-more:hover .arrorright {
	color: #fff;
}
.card {
	overflow: hidden;
}
.card-img-top {
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 100%;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.card-img-top:hover {
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
	-webkit-transform: scale(1.08);
	        transform: scale(1.08);
}
.imgwrap {
	overflow: hidden;
}

/* meadiaquery starts */

@media (max-width: 1280px) {
	.card-title.h5 {
		margin-top: 0;
	}
	.card-title {
		font-size: 26px;
	}
	.price.card-text {
		font-size: 22px;
	}
}
@media (max-width: 1024px) {
	.show-more .arrorright {
		margin-left: 10px;
	}
}
@media (max-width: 768px) {
	.expcard {
		flex: 100% 1;
		max-width: 100%;
	}
	.expimg img {
		min-height: auto;
		max-height: inherit;
	}
}
@media (max-width: 767px) {
	.featuredexp {
		padding: 0 20px;
	}
}

/* mediaquery ends */

#thesoulcompany {
	padding: 100px 155px;
	color: #fff;
	font-size: 16px;
}

#thesoulcompany h2 {
	font-size: 80px;
	color: #fff;
	line-height: 85px;
	text-align: left;
}
#thesoulcompany h2::after {
	content: none;
}
#thesoulcompany .border-btn {
	border: 1px solid transparent;
	padding: 5px 30px;
	margin: 40px 0 50px;
	display: table;
	background: #f15b3b;
	color: #fff;
}
#thesoulcompany .border-btn:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
}

#thesoulcompany p {
	padding-bottom: 10px;
	width: 80%;
}
/* meadiaquery starts */
@media (max-width: 1366px) {
	#thesoulcompany h2 {
		padding-bottom: 30px;
	}
}
@media (max-width: 1280px) {
	#thesoulcompany h2 {
		font-size: 64px;
		line-height: 74px;
	}
}
@media (max-width: 1024px) {
	#thesoulcompany h2 {
		font-size: 50px;
		line-height: 55px;
	}
	#thesoulcompany {
		padding: 100px 50px;
	}
	#thesoulcompany .border-btn {
		margin: 28px 0 50px;
	}
	#thesoulcompany p {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.fullwidth {
		flex-basis: auto;
	}
	#thesoulcompany h2 {
		font-size: 65px;
		line-height: 70px;
	}
}
@media (max-width: 767px) {
	#thesoulcompany h2 {
		font-size: 45px;
		line-height: 56px;
	}
	#thesoulcompany p {
		width: 100%;
	}
}
/* mediaquery ends */

.section-05 {
	margin-bottom: 100px;
}
.section-05 .card[style] {
	margin-right: 20px;
	width: auto !important;
}
.section-05 .card-body {
	padding: 0px;
}
.section-05 .card .card-title {
	float: left;
}
.section-05 .card span {
	float: right;
	margin-top: 30px;
	opacity: 0.5;
}
.section-05 .card-text {
	font-size: 16px;
}

h2.heading {
	margin-bottom: 50px;
}
svg.svg-inline--fa.fa-arrow-left.fa-w-14.arrow-left {
	float: right;
	position: relative;
	top: -30px;
	right: 100px;
}
svg.svg-inline--fa.fa-arrow-right.fa-w-14.arrow-right {
	position: absolute;
	top: -30px;
	right: 40px;
}
.svg-inline--fa {
	font-size: 20px;
}
.container-grid-insta {
	padding-left: 40px;
}
.instaimg img {
	width: 100%;
	min-height: 296px;
	max-height: 296px;
	object-fit: cover;
}
/* meadiaquery starts */
@media (max-width: 1440px) {
	.section-05 .card .card-title {
		float: left;
		margin-bottom: 0;
	}
	.section-05 .card span {
		float: left;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.instaimg img {
		width: 100%;
		min-height: 200px;
		max-height: 200px;
	}
}
@media (max-width: 1024px) {
	.card-title.h5 {
		margin-top: 10px;
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	.section-05 .card[style] {
		margin-right: 0;
	}
	.section-05 {
		width: 100%;
		margin: 0 auto;
	}
	.section-05 .card span {
		display: block;
		float: none;
	}
	.section-05 .card .card-title {
		float: none;
	}
}
/* mediaquery ends */

#newsletter {
	width: 70%;
	margin: 0 auto;
	text-align: center;
}

label.heading {
	font-size: 60px;
	text-align: center;
	line-height: 62px;
	text-transform: none;
	margin-bottom: 15px;
	margin: 50px 0 100px 0;
	position: relative;
	font-family: "Chronicle Text G1";
}
label.heading:before {
	content: "";
	width: 97px;
	height: 4px;
	background: #f15b3b;
	display: block;
	margin: 0 auto;
	bottom: -25px;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}
#newsletter .form-control {
	width: 70%;
	float: left;
	line-height: 60px;
	font-size: 20px;
	height: 60px;
	border: 1px solid #f15b3b;
	border-radius: 0;
}
#newsletter .submit-btn {
	width: 30%;
	height: 60px;
	font-size: 20px;
	line-height: 50px;
	background: #f15b3b;
	color: #fff;
	text-transform: none;
	border-radius: 0;
	border: 1px solid transparent;
}
#newsletter .form-text {
	position: absolute;
	width: auto;
	top: 110px;
	color: #f15b3b;
}
#newsletter .suc_text {
	color: #48ac15;
}
#newsletter .err_text {
	color: #f15b3b;
}
.form-group {
	margin-bottom: 0rem;
}

nav.f-social.navbar.navbar-expand.navbar-light .navbar-nav .nav-link {
	padding: 15px 18px;
}

/* meadiaquery starts */
@media (max-width: 1024px) {
	#newsletter {
		width: 50%;
	}
	#newsletter .form-control,
	#newsletter .submit-btn {
		height: 70px;
	}
}
@media (max-width: 767px) {
	.section-06 h2.heading {
		margin-bottom: 0;
	}
	#newsletter .form-group input::-webkit-input-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input:-ms-input-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input::-moz-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input::-ms-input-placeholder {
		text-align: center;
		color: #000;
	}

	#newsletter .form-group input::placeholder {
		text-align: center;
		color: #000;
	}
}
/* mediaquery ends */

.f-logo {
	margin-top: 25px;
}
.footer-address {
	background: #f6f6f6;
	padding: 50px 55px 0px 55px;
	margin-top: -30px;
}
.navbar {
	padding-left: 0rem;
}
.navbar-light .navbar-nav a {
	padding: 15px 25px;
	font-size: 18px;
	color: #999898;
	line-height: 30px;
}
.navbar-light .navbar-nav a:first-child {
	padding-left: 0px;
}

.f-social .fa {
	font-size: 30px;
	color: #000;
	cursor: pointer;
}
.powerby {
	float: right;
	padding-top: 17px;
}
.f-right {
	float: left;
	margin-right: 20px;
	position: relative;
	padding: 0;
}
.f-right.navbar-light .navbar-nav a {
	color: #000;
	font-size: 16px;
	font-family: "Chronicle Text G1";
}
.bottomcopy {
	background: #fff;
}

.copy {
	padding-top: 25px;
}
.scoial-icon {
	font-size: 28px;
}

nav.f-social.navbar.navbar-expand.navbar-light {
	float: right;
}

.navbar-light .navbar-nav a .scoial-icon:hover.facebook {
	color: #3b5998;
	text-shadow: 0 0 15px #3b5998;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.navbar-light .navbar-nav a .scoial-icon:hover.twitter {
	color: #00aced;
	text-shadow: 0 0 15px #00aced;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.navbar-light .navbar-nav a .scoial-icon:hover.youtube {
	color: #c4302b;
	text-shadow: 0 0 15px #c4302b;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.navbar-light .navbar-nav a .scoial-icon:hover.linkdein {
	color: #0e76a8;
	text-shadow: 0 0 15px #0e76a8;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.f-right.navbar-light .navbar-nav a {
	padding: 15px 10px;
}

.f-right.navbar-light .navbar-nav a:hover {
	color: #f15b3b;
}
.powerby a {
	margin-left: 10px;
}
.displayflex {
	display: flex;
	justify-content: flex-end;
}
span.hr-line {
	width: 1px;
	height: 10px;
	background: #999;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	margin: 25px 0;
	margin-right: 20px;
}
.copy-phn {
	display: none;
}
.f-right.navbar-light .navbar-nav a.active {
	color: #000;
	font-weight: 600;
}
.footer-address .navbar-light .navbar-nav a.active {
	color: #000;
	font-weight: 600;
}
/* meadiaquery starts */
@media (max-width: 1024px) {
	.bottomcopy p,
	.f-right.navbar-light .navbar-nav a,
	.powerby {
		font-size: 14px;
	}
	.copy {
		padding-top: 15px;
	}
}
@media (max-width: 768px) {
	.copy {
		padding-top: 15px;
		display: block;
		width: 100%;
		float: none;
		flex: 100% 1;
		max-width: 100%;
		text-align: center;
	}
	.displayflex {
		display: flex;
		flex: 100% 1;
		max-width: 100%;
		justify-content: center;
	}
	.footer-address {
		margin-top: 0;
	}
	#newsletter {
		width: 80%;
		margin-bottom: 50px;
	}
	#newsletter .submit-btn,
	#newsletter .form-control {
		width: 100%;
		margin-bottom: 20px;
	}
	.f-logo.col img {
		margin: 0 auto;
		display: block;
	}
	.section-07 .navbar-nav {
		text-align: center;
		justify-content: center;
	}
	.section-07 .navbar {
		display: block;
		padding: 0 0 10px;
	}
	.navbar-light .navbar-nav a {
		font-size: 15px;
	}
	nav.f-social.navbar.navbar-expand.navbar-light {
		float: none;
	}
	.displayflex {
		display: block;
		flex: 100% 1;
		max-width: 100%;
		justify-content: center;
	}
	.f-right {
		float: none;
		margin-right: 0;
		text-align: center;
		display: block;
	}
	.f-right.navbar-expand .navbar-nav {
		text-align: center;
		justify-content: center;
	}
	.powerby {
		float: none;
		padding-top: 0;
		text-align: center;
		padding-bottom: 20px;
	}
	.f-logo {
		margin-bottom: 15px;
	}
	.f-right .navbar-light .navbar-nav a {
		padding: 8px 10px;
	}
	.scoial-icon {
		font-size: 24px;
	}
	span.hr-line {
		display: none;
	}
	.footer-address .navbar-light .navbar-nav a {
		padding: 5px 5px 5px;
		font-size: 14px;
	}
	.copy-hide {
		display: none;
	}
	.copy-phn {
		display: block;
	}
	nav.f-right.navbar.navbar-expand.navbar-light,
	.f-right.navbar-light .navbar-nav a {
		padding-bottom: 0;
	}
	.section-07 .col-md-8 {
		max-width: 100%;
		flex: 100% 1;
	}
	.section-07.footer-address.container-grid.container-fluid .col-md-4 {
		max-width: 100%;
		flex: 100% 1;
	}
	.formcont .icon-angle-down .btn-primary {
		min-width: auto;
		width: 100%;
	}
}

/* maediaquery ends */

.expdetailtopimg {
	height: 720px;
}

/* mediaquery css starts */
@media (max-width: 1440px) {
	.expdetailtopimg {
		height: 500px;
	}
}
@media (max-width: 1024px) {
	.expdetailtopimg {
		height: 340px;
	}
}
@media (max-width: 767px) {
	.expdetailtopimg {
		height: 155px;
	}
}
/* mediaquery css ends */

select#formGridguest,
select#formGriddate {
	height: 50px;
}
.share-icons .fa {
	margin-left: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-top: 15px;
}
.booking .form-row {
	background: #f6f6f6;
	padding: 30px;
}
.date-input.form-group.col {
	display: flex;
	border: 1px solid #d9d9d9;
	background: #fff;
	width: 50%;
	max-height: 60px;
	margin-top: 20px;
}
.booking label {
	font-size: 22px;
	text-align: left;
	color: #000;
	display: block;
	width: 100%;
}
.booking .form-control {
	background-color: transparent;
	background-image: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: #000;
}
.booking .selectcontrol.form-group.col {
	padding-left: 0;
	padding-left: 0;
	display: flex;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #e1e1e1;
	border-radius: 0;
	box-shadow: none;
	color: #000;
	font-size: 16px;
	height: 60px;
	padding: 6px 25px;
	margin-right: 10px;
}
.booking .arrow-down {
	margin-top: 8px;
}
.booking select#formGridguest {
	-webkit-appearance: none;
}
button.pre-book-btn.btn.btn-primary {
	display: block;
	width: 100%;
	border: 1px solid transparent;
	padding: 10px 30px;
	margin: 0;
	display: table;
	background: #f15b3b;
	color: #fff;
}
button.pre-book-btn.btn.btn-primary:hover {
	background: #fff;
	color: #f15b3b;
	border: 1px solid #f15b3b;
}
button.pre-book-btn.btn.btn-primary:disabled,
button.pre-book-btn.btn.btn-primary:hover:disabled {
	background: #c2b7b7;
	color: #fff;
	border: 1px solid #c2b7b7;
}
input.choosedate {
	border: 0;
	background: transparent;
	width: 90%;
	padding-left: 20px;
}
.calender-icon {
	margin-top: 18px;
	margin-right: 20px;
}
input:focus {
	outline: none;
}
.date-input input::-webkit-input-placeholder {
	color: #000;
}

.date-input input:-ms-input-placeholder {
	color: #000;
}

.date-input input::-moz-placeholder {
	color: #000;
}

.date-input input::-ms-input-placeholder {
	color: #000;
}

.date-input input::placeholder {
	color: #000;
}
.DayPicker {
	position: absolute;
	background: #fff;
	right: 0;
	top: 60px;
	border: 1px solid #d9d9d9;
	z-index: 2;
	width: auto;
}
.DayPicker-Month {
	width: 100%;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: #f15b3b;
}
.DayPicker-Day {
	border-radius: 0;
}
.DayPicker-Day--disabled {
	color: #d9d9d9;
	position: relative;
}
.DayPicker-Day--disabled::after {
	content: "";
	position: absolute;
	width: 20px;
	height: 1px;
	background: #d9d9d9;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
}
.note {
	font-size: 16px;
	color: #000;
	line-height: 26px;
	padding-top: 10px;
	padding-bottom: 20px;
}
.note .txt-orange {
	font-weight: 500;
}
.modal-title {
	font-size: 40px;
	color: #000;
}
.modal-content {
	padding: 0 50px 50px;
}
.modal-header {
	border-bottom: 0;
}
.modal-body h4 {
	font-size: 25px;
	color: #000;
}
.modal-footer {
	border-top: 0;
	justify-content: flex-start;
}
.modal-body .form-group {
	margin: 0;
}
.modal-body .form-control {
	border: 0;
	font-size: 18px;
	border-bottom: 1px solid #d9d9d9;
	padding: 6px 5px;
}
.close {
	opacity: 1;
	font-size: 30px;
}
button.pre-book-btn.border-btn.btn.btn-primary {
	margin-top: 15px;
}
.booking .err_text {
	display: block;
	width: 100%;
	color: #f15b3b !important;
	font-size: 16px;
}
.booking .looks_like_link {
	color: #f15b3b !important;
	cursor: pointer;
	font-size: 16px;
}
.booking .looks_like_link:hover {
	text-decoration: underline;
}
form.booking.fixbookform {
	position: fixed;
	/* top: 50%;
	transform: translateY(-50%); */
	top: 15%;
	width: 30%;
	z-index: 999;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}
form.booking.stickbookform {
	position: fixed;
	width: 30%;
	top: 15%;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}
.show-mbl {
	display: none;
}
/* mediaquery css starts */
@media (min-width: 1024px) {
	.booking .form-row {
		position: -webkit-sticky;
		position: sticky;
		top: 120px;
	}
	.Expdetailexpectwrap.row .col-md-8 {
		flex: 100% 1;
		max-width: 100%;
	}
}
@media (max-width: 1440px) {
	.DayPicker {
		width: auto;
	}
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		min-width: 160px;
	}
}
@media (max-width: 1024px) {
	input.choosedate {
		padding-left: 10px;
	}
	.calender-icon {
		margin-right: 10px;
	}
	.booking .form-row {
		padding: 30px 10px;
	}
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		min-width: 120px;
	}
	.booking label {
		font-size: 20px;
	}
}
@media (max-width: 768px) {
	.date-input.form-group.col {
		width: 100%;
		height: 50px;
		margin: 0 auto;
	}
	.calender-icon {
		margin-top: 15px;
	}
	input.choosedate {
		width: 100%;
	}
	.DayPicker {
		left: 0;
		width: 100%;
		top: 50px;
	}
	.modal-body .form-row {
		padding: 0;
	}
	.animatefullwidth.animated.fadeInRight {
		width: 100%;
	}
	form.booking {
		display: none;
	}
	.show-mbl {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		z-index: 3;
	}
	.displayflex.col-md-8.col-sm-8 {
		padding: 50px 0;
	}
	#expdetailgallery {
		position: relative;
		z-index: 0;
	}

	form.reserve-form.booking {
		display: block;
	}
	.wrap-detail.col-sm-8 {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.modal-content {
		padding: 0 15px 20px;
	}
	.modal-title {
		font-size: 30px;
	}
	.close {
		font-size: 22px;
	}
	.modal-body h4 {
		font-size: 20px;
	}
	form.booking {
		margin-top: 50px;
	}
	#expdetailgallery .gallery:hover .slick-slider button {
		display: block !important;
	}
	.reserve-popup form.booking {
		margin-top: 0;
	}
	.reserve-popup .booking label {
		padding: 5px 5px;
	}
	.reserve-popup .icon-angle-down .btn-primary {
		min-width: 0;
		min-width: initial;
		width: 100%;
		border: 1px solid #ccc;
	}
	.reserve-popup .DayPicker-Day {
		padding: 0.1em;
	}
}
/* mediaquery css ends */

.booking .dropdown.in-valid .dropdown-toggle {
	border: 1px solid #f15b3b;
}
.booking .date-input.in-valid {
	border: 1px solid #f15b3b;
}
.booking .invalid-dates {
	font-size: 16px;
	color: #f15b3b;
}

#expdeatilstory h2::after {
	content: none;
}
#expdeatilstory h2 {
	text-align: left;
	padding: 0;
}
.storyprice {
	color: #f15b3b;
	font-size: 22px;
	margin-top: 10px;
}
#expdeatilstory {
	padding: 45px 50px 0;
	z-index: 0;
	position: relative;
}
#expdeatilstory p {
	padding: 10px 0 0;
	color: #808080;
	line-height: 24px;
	font-size: 14px;
	padding-right: 50px;
}
.heart-icon {
	margin-right: 15px;
}
.heart-icon:hover,
.share-icon:hover {
	color: #f15b3b;
}
.expdetail-social-icon.col-sm-1 {
	padding: 30px 0;
	text-align: left;
}
.expdetailstorybookwrap {
	display: flex;
	justify-content: flex-end;
}
span.expdetail-social-icon {
	border-left: 1px solid #000;
	margin: 0 15px;
	color: #000;
}
svg.svg-inline--fa.fa-heart.fa-w-16.heart-icon {
	margin-left: 15px;
}
.offerprice {
	border: 2px dashed #f15b3b;
	width: 15%;
	padding: 10px;
	background: #f6f6f6;
	margin-top: 30px;
}
#expdeatilstory .offerprice p {
	padding: 0;
	color: #000;
}
.offerprice h3 {
	font-weight: 900;
	font-size: 20px;
}
.date-input span.err_text {
	display: block;
	width: 100%;
}
/* .section-06.container {
	z-index: -1;
	position: relative;
}
.section-07.footer-address.container-grid.container-fluid {
	position: relative;
	z-index: -2;
} */
.hideindesk {
	display: none;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	#expdeatilstory h2 {
		font-size: 50px;
		line-height: 72px;
	}
	.offerprice {
		width: 20%;
	}
	.wrap-detail.col-sm-8 {
		margin-top: -150px;
	}
	/* .ril__image {
		transform: translate3d(0px, 0px, 0px) scale3d(0.824623, 0.824623, 1) !important;
	} */
	img.ril__image {
		width: auto;
	}
}
@media (max-width: 1366px) {
	.calender-icon {
		margin-top: 18px;
	}
	.booking .form-control {
		padding: 6px 0;
	}
	.booking .selectcontrol.form-group.col {
		padding: 6px 12px;
	}
	input.choosedate {
		padding-left: 12px;
	}
	.calender-icon {
		margin-right: 12px;
	}
	.offerprice {
		width: 22%;
	}
}
@media (max-width: 1280px) {
	#expdeatilstory h2 {
		font-size: 48px;
	}
	.offerprice {
		width: 26%;
	}
}
@media (max-width: 1024px) {
	#expdeatilstory h2 {
		font-size: 34px;
		line-height: 46px;
	}
	.storyprice {
		font-size: 20px;
	}
	#expdeatilstory p {
		padding-right: 20px;
	}
	.offerprice {
		width: 28%;
	}
	input.choosedate,
	.expdetailstorybookwrap .icon-angle-down .btn-primary {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
	#expdeatilstory .col-sm-7 {
		flex: 100% 1;
		max-width: 100%;
	}
	#expdeatilstory .col-sm-4,
	.expdetail-social-icon.col-sm-1 {
		flex: 100% 1;
		max-width: 100%;
	}
	.expdetail-social-icon.col-sm-1 {
		padding: 30px 20px;
	}
	.booking .selectcontrol.form-group.col {
		padding: 6px 20px;
	}
	.offerprice {
		width: 38%;
	}
	.wrap-detail.col-sm-8 {
		flex: 100% 1;
		max-width: 100%;
	}
	img.ril__image {
		width: auto;
	}
	.expdeatilstorywrap .wrap-detail.col-sm-8 {
		margin-top: 0;
	}
	.hideindesk {
		display: block;
	}
	.cancel-wrapper .cmn-btn-inverse.btn.btn-primary {
		height: 50px;
	}
}

@media (max-width: 767px) {
	#expdeatilstory h2 {
		font-size: 32px;
		line-height: 40px;
	}
	#expdeatilstory p {
		padding-right: 0;
	}
	.expdetail-social-icon.col-sm-1 {
		padding: 0 20px 20px;
	}
	#expdeatilstory {
		padding: 45px 30px 0;
	}
	.offerprice {
		width: 53%;
	}
	#expdeatilstory .offerprice h2 {
		text-align: center;
	}
}
/* mediaquery css ends */

#Expdetailexpect h2 {
	text-align: left;
	padding: 0;
	margin-bottom: 20px;
}
#Expdetailexpect h2::after {
	content: none;
}
#Expdetailexpect {
	padding: 50px 0px 0;
}

#Expdetailexpect p {
	padding-bottom: 10px;
	color: #808080;
	line-height: 24px;
	font-size: 14px;
}
.author-wrap {
	margin-bottom: 30px;
}
.wrap-detail {
	margin-top: -220px;
	background: #fff;
	padding: 30px;
}
.Expdetailexpectwrap.drop-line.row {
	margin-top: 40px;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.Expdetailexpectwrap h2 {
		font-size: 50px;
		line-height: 72px;
	}
}
@media (max-width: 1024px) {
	.Expdetailexpectwrap h2 {
		font-size: 38px;
		line-height: 50px;
	}
}
@media (max-width: 768px) {
	.expdetailstorybookwrap {
		justify-content: flex-start;
		margin-top: 30px;
	}
	#Expdetailexpect {
		position: relative;
		z-index: -1;
	}
}
@media (max-width: 767px) {
	.Expdetailexpectwrap h2 {
		font-size: 32px;
		line-height: 40px;
	}
	#Expdetailexpect {
		padding: 0 30px;
	}
}

/* mediaquery css ends */

#expdetailgallery {
	padding: 80px 50px;
}
#expdetailgallery h2 {
	text-align: left;
	padding: 0;
	padding-bottom: 30px;
}
#expdetailgallery h2::after {
	content: none;
}
#expdetailgallery .sync-slick img {
	width: 100%;
}
#expdetailgallery .slick-slider.nav-slick.slick-initialized {
	width: 50%;
	margin: 0 auto;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	z-index: 2;
}
.ril__image {
	width: 60%;
}
.ReactModal__Content.ReactModal__Content--after-open {
	top: 110px !important;
}
#expdetailgallery .sync-slick {
	position: relative;
	z-index: 1;
}
#expdetailgallery .slick-prev:before {
	content: "";
	background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAAeAAD/4QMraHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzYgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkY4NjUwODNBREVCQTExRTlCNTE3ODJGQTQ3RkM2MDY2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkY4NjUwODNCREVCQTExRTlCNTE3ODJGQTQ3RkM2MDY2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Rjg2NTA4MzhERUJBMTFFOUI1MTc4MkZBNDdGQzYwNjYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjg2NTA4MzlERUJBMTFFOUI1MTc4MkZBNDdGQzYwNjYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAAQCwsLDAsQDAwQFw8NDxcbFBAQFBsfFxcXFxcfHhcaGhoaFx4eIyUnJSMeLy8zMy8vQEBAQEBAQEBAQEBAQEBAAREPDxETERUSEhUUERQRFBoUFhYUGiYaGhwaGiYwIx4eHh4jMCsuJycnLis1NTAwNTVAQD9AQEBAQEBAQEBAQED/wAARCAAtADMDASIAAhEBAxEB/8QAYAABAAMBAAAAAAAAAAAAAAAAAAMEBQcBAQAAAAAAAAAAAAAAAAAAAAAQAAEDAgUEAwEAAAAAAAAAAAABAgMEBREhMUESUTJSE/AiFAYRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AOgAAAAR/og9603sZ+hG+xYuSc+GOHLjrhjuBIAAAAAAADF/oL7Jb3QUFFGkt0rl4UrX/WNuyve5csumpJZLGy2JJUTyLVXOpzqqt+rl8W+LE2Qs3W00d2pFpatuLe6N7cnxvTR7HbKhTskl5gmktd1Ys6QN5U9yb2zMxwRsnSRPnVQ2QAAAAAAAAAAAAAAAf//Z) no-repeat;
	width: 51px;
	height: 45px;
	display: block;
}
#expdetailgallery .slick-next:before {
	content: "";
	background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAAeAAD/4QMraHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzYgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjA1NjA3REFGREVCQjExRTlCM0ZFREY1MzBEMjAyRkMwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjA1NjA3REIwREVCQjExRTlCM0ZFREY1MzBEMjAyRkMwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDU2MDdEQURERUJCMTFFOUIzRkVERjUzMEQyMDJGQzAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDU2MDdEQUVERUJCMTFFOUIzRkVERjUzMEQyMDJGQzAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAAQCwsLDAsQDAwQFw8NDxcbFBAQFBsfFxcXFxcfHhcaGhoaFx4eIyUnJSMeLy8zMy8vQEBAQEBAQEBAQEBAQEBAAREPDxETERUSEhUUERQRFBoUFhYUGiYaGhwaGiYwIx4eHh4jMCsuJycnLis1NTAwNTVAQD9AQEBAQEBAQEBAQED/wAARCAAtADMDASIAAhEBAxEB/8QAZgABAAIDAQAAAAAAAAAAAAAAAAMFAQIGBwEBAAAAAAAAAAAAAAAAAAAAABAAAQQBAgMHBQAAAAAAAAAAAAECAwQRIQUxURJhofEiMhMjQ1MUFQYRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APQAAABHPPBXidNYkbDE3HVI9yNamVwmVdpxN0VFTKaooGQAAAAAAARWa1e3XkrWY0lglTpex3BUOahtWP5S5Bt1yRbOz2n+3RmXzTQO+05qaubyVOB0O4WZalOWxDA+1LGmWQR+p7lXCJ369hV7Rslj8n9zvbkn3R6fGxNYqrF+nEnPmvioXoAAAAAAAAAAAAAAAP/Z) no-repeat;
	width: 51px;
	height: 45px;
	display: block;
}
#expdetailgallery .slick-next {
	right: 0;
	top: 50%;
	bottom: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	width: 51px;
	height: 45px;
}
#expdetailgallery .slick-prev {
	left: 0;
	top: 50%;
	bottom: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 2;
	width: 51px;
	height: 45px;
}
.nav-slick div img {
	margin: 15px;
	width: 90%;
}
.gallery .slick-slider button {
	display: none !important;
}
.gallery:hover .slick-slider button {
	display: block !important;
	-webkit-transition: 0.4s all ease;
	transition: 0.4s all ease;
}
.nav-slick .slick-slide.slick-active {
	opacity: 0.5;
}
.nav-slick .slick-slide.slick-active.slick-center.slick-current {
	opacity: 1;
}
#expdetailgallery .slick-slider.nav-slick button {
	display: none !important;
}
.react-fancybox .box {
	z-index: 99;
}
/* mediaquery css starts */

@media (max-width: 1440px) {
	.nav-slick div img {
		width: 95%;
	}
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		width: 70%;
	}
	#expdetailgallery h2 {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 1280px) {
	.nav-slick div img {
		width: 95%;
	}
}
@media (max-width: 1024px) {
	#expdetailgallery h2 {
		font-size: 38px;
		line-height: 50px;
	}
}
@media (max-width: 768px) {
	.nav-slick .slick-track {
		margin: 0 auto;
	}
	nav.f-social.navbar.navbar-expand.navbar-light a {
		padding: 0 10px;
	}
}
@media (max-width: 767px) {
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		position: static;
	}

	#expdetailgallery {
		padding: 50px 30px 0;
	}
	#expdetailgallery .slick-slider.nav-slick.slick-initialized {
		width: 100%;
	}
	.nav-slick div img {
		width: 90%;
		margin: 0 auto;
	}
	.gallery .slick-slider button {
		display: block !important;
	}
	#expdetailgallery .sync-slick img {
		height: 18vh;
		object-fit: cover;
	}
}
/* mediaquery css ends */

.explistingtitle{
    margin-bottom: 20px;
}
.filtercont .searchfilter {
	padding: 54px 0px;
}
.filtercont {
	background: #f6f6f6;
}
.filtercont .form-control {
	font-size: 16px;
	border: none;
	width: 100%;
	display: inline-block;
}
.filtercont .formcont {
	width: 100%;
	margin: 0 auto;
}
.filtercont .submit-btn {
	display: inline-block;
	background: #f15b3b;
	border: 1px solid transparent;
	color: #fff;
	border-radius: 0rem;
	width: 160px;
	line-height: 0px;
	margin-top: 20px;
	text-transform: inherit;
}
.filtercont .submit-btn:hover {
	color: #f15b3b;
	border: 1px solid #f15b3b;
	background: #fff;
}
.form-control {
	font-size: 16px;
	color: #000;
}
.icon-angle-down .dropdown-toggle::after {
	-webkit-appearance: none;
	-moz-appearance: window;
	padding: 2px 50px 2px 2px;
	border: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjJDODdDRDJFNUFCMTFFOTg3NjA4RjQwMDg4ODVCODEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjJDODdDRDNFNUFCMTFFOTg3NjA4RjQwMDg4ODVCODEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MkM4N0NEMEU1QUIxMUU5ODc2MDhGNDAwODg4NUI4MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MkM4N0NEMUU1QUIxMUU5ODc2MDhGNDAwODg4NUI4MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PlGR3dAAAABrSURBVHjaYmCAAC4GygHcjGAg/gvEzRQY1gbE/4A4CMSxB+JfQPwfKkEq6IDq/QnEdsiuJMdQZMOC0CVJNRSvYaQaSpRh2Axtp9QwZEN/YjGULMNwGUqRYdgMpdgwdEOpYhiyoYHEKAQIMADhqTLjnZcaNwAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	background-position: right center;
	display: inline;
	display: initial;
	vertical-align: middle;
	position: absolute;
	z-index: 2;
	right: 20px;
	width: 20px;
	height: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active,
.icon-angle-down .show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #fff;
	border-color: transparent;
	border: 0;
	border-radius: 0;
}
.icon-angle-down .btn-primary {
	color: #000;
	background: #fff;
	border: 0;
	border-radius: 0;
	padding: 15px;
	font-size: 16px;
	margin: 20px 0;
	min-width: 200px;
	text-align: left;
	height: 60px;
}
.icon-angle-down .btn-primary:hover {
	background-color: #fff;
	color: #000;
	border: 0;
	border-color: transparent;
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active,
.icon-angle-down .show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #d9d9d9;
}
.icon-angle-down .btn-primary:not(:disabled):not(.disabled).active:focus,
.icon-angle-down .btn-primary:not(:disabled):not(.disabled):active:focus,
.icon-angle-down .show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: #000;
	background-color: #fff;
	border-color: #d9d9d9;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.icon-angle-down .dropdown-menu {
	width: 100%;
	background: #fff;
	border: 0;
	border-radius: 0;
}
.icon-angle-down .dropdown-item:focus,
.icon-angle-down .dropdown-item:hover {
	color: #000;
	background-color: #d9d9d9;
}
.icon-angle-down .dropdown-item.active,
.icon-angle-down .dropdown-item:active {
	color: #000;
	text-decoration: none;
	background-color: #d9d9d9;
}
.icon-angle-down .dropdown-item {
	color: #000;
}
.icon-angle-down.dropdown {
	margin-right: 10px;
	position: relative;
}
.dropdown-menu.show {
	max-height: 200px;
	overflow-y: scroll;
	border: 1px solid #d9d9d9;
}
.profile-dropdown.icon-angle-down .dropdown-menu.show {
	max-height: 325px !important;
	overflow-y: scroll;
	border: 1px solid #d9d9d9;
}
.no-scroll .dropdown-menu.show {
	overflow-y: auto;
}
/* mediaquery css starts */
@media (max-width: 1024px) {
	.filtercont .formcont {
		width: 100%;
	}
}
@media (max-width: 768px) {
	.form-row {
		display: block;
		padding: 0 30px;
	}
	.filtercont .form-group {
		margin-bottom: 20px;
	}
	.filtercont .submit-btn {
		margin: 0 auto;
		display: block;
		width: 100%;
	}
	.icon-angle-down.dropdown {
		background: #fff;
		margin: 10px 0;
	}
	.icon-angle-down .btn-primary {
		margin: 0;
		height: auto;
	}
}
@media (max-width: 767px) {
	.filtercont .form-group {
		margin-top: 0;
	}
	.filtercont .form-control {
		width: 90%;
	}
}

/* medaiquery css ends */

.warn_msg {
	color: #f15b3b;
	font-size: 14px;
	margin-top: -10px;
}

.sorting {
	margin: 50px 18px 25px;
}
.align-right {
	display: flex;
	justify-content: flex-end;
}
.align-right h4 {
	display: inline-block;
	margin-right: 20px;
	font-size: 16px;
	line-height: 40px;
}

button.show-more {
	font-size: 20px;
	color: #f15b3b;
	text-decoration: none;
	border: 1px solid #f15b3b;
	border-radius: 0;
	padding: 10px 30px;
	font-size: 16px;
	font-weight: 600;
}
button.show-more:hover {
	background: #f15b3b;
	color: #fff;
}
button.show-more:hover .arrorright {
	color: #fff;
}
.sort-list-number h6,
.sort-list-number h6 a {
	font-size: 16px;
	color: #000;
}
.angle-right {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
.img-like {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 20px;
	background-color: #fff;
	padding: 15px;
	color: #f15b3b;
	border: none;
}
.img-like:hover {
	background: #f15b3b;
	color: #fff;
}
.img-like:focus {
	outline: none;
}
.img-like.wishlisted {
	background-color: #f15b3b;
	color: #fff;
}
.imgwrap {
	position: relative;
}
.sort-list-number {
	padding-top: 10px;
}
.sort-dropdown.icon-angle-down.dropdown {
	border: 1px solid #d9d9d9;
}
.sort-dropdown.icon-angle-down .btn-primary {
	height: auto;
	padding: 10px 15px;
	margin: 0;
}
.NoExpToShow {
	margin: 100px auto 0;
}
/* mediaquery css starts */
@media (max-width: 767px) {
	.align-right {
		display: flex;
		justify-content: flex-start;
		padding: 0;
	}
	.align-right form {
		width: 75%;
	}
	.sort-list-number {
		margin-bottom: 15px;
		padding: 0;
	}
}
/* mediaquery css ends */

.otp-no input {
	height: 30px;
	width: 3rem;
	min-width: 50px;
	background: #fff;
	border: 0px solid #c6c6c6;
	border-bottom: 1px solid #c6c6c6;
	outline: none;
}
.otp-no span {
	height: 1px;
	width: 10px;
}
.otp-no a {
	color: #f15b3b;
	display: inline-flex;
	vertical-align: text-top;
	margin-left: 10px;
	justify-content: flex-end;
	width: 30%;
}
.otpinput {
	display: inline-block;
	vertical-align: bottom;
}

.regac {
	font-size: 18px;
	color: #000;
}
div#formOtp {
	width: 100%;
}
.regac.form-group a {
	padding-left: 10px;
}

.progress {
	width: 30%;
	height: 12px;
	border-radius: 10px;
	display: inline-flex;
}
.fieldError {
	display: inherit;
}
.errormsg {
	font-size: 16px;
	color: #000;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}
.regform .form-row {
	width: 100%;
}
.regform .otp-no a {
	width: auto;
	margin-left: 0;
	margin-top: 10px;
	display: block;
}
.message-box .regform label {
	color: #c6c6c6;
	line-height: 24px;
}
.message-box .regform label.form-check-label {
	color: #000;
}
.icon-angle-down.regdrpdwn.dropdown {
	border-bottom: 1px solid #d9d9d9;
	width: calc(100% - 30px);
	height: 82px;
}
.icon-angle-down.regdrpdwn.dropdown .btn-primary {
	margin-bottom: 0;
	color: #c6c6c6;
}
.icon-angle-down.regdrpdwn.dropdown .dropdown-toggle::after {
	width: 10px;
	height: 10px;
	top: 70%;
}
.errorMsg {
	color: red;
	display: block;
	width: 100%;
}
.avai_text {
	color: green !important;
}
img.cancel-image {
	width: 20px;
	height: 20px;
	margin-top: 25px;
}
img.check-image {
	width: 23px;
	height: 30px;
	margin-top: 10px;
	margin-left: 10px;
}
.pincodeinput.form-group.col {
	margin-top: 28px;
}
button.cmn-btn.btn.btn-primary:disabled {
	color: #fff;
	background-color: #f15b3b;
	border-color: #f15b3b;
	cursor: not-allowed;
}
button.dropdown-toggle.btn.btn-primary:disabled {
	cursor: not-allowed;
}
.form-control:disabled,
.form-control[readonly] {
	cursor: not-allowed;
}
.message-box .regform label.form-check-label:hover {
	color: #f15b3b;
	cursor: pointer;
}
.message-box .regform label.form-check-label {
	display: inline-block;
	vertical-align: middle;
}
.d-flex-phn {
	width: 100%;
	display: flex;
}
/* mediaquery css starts */

@media (max-width: 1440px) {
	.pincodeinput.form-group.col input {
		top: 12px;
	}
}
@media (max-width: 1024px) {
	.otp-no input {
		min-width: 30px;
	}
}
@media (max-width: 768px) {
	.mx-auto.register-form.col-md-10.col-sm-12 {
		flex: 100% 1;
		max-width: 100%;
	}
	.register-form .form-row {
		padding: 0;
	}
	.regform .d-flex {
		display: block !important;
	}
	.pincodeinput.form-group.col input,
	.pincodeinput.form-group.col .errorMsg {
		position: static;
	}
	.icon-angle-down.regdrpdwn.dropdown {
		height: auto;
	}
}
@media (max-width: 767px) {
	.otp-no input {
		min-width: 22px;
	}
	.otp-no a {
		width: 100%;
		justify-content: flex-start;
	}
	.regac {
		font-size: 16px;
	}
	.regac .txt-orange {
		margin-left: 10px;
	}
	.otp-no a {
		margin-left: 0;
		padding-top: 10px;
	}
	.message-box .regform label.form-check-label {
		display: inline-block;
		vertical-align: middle;
	}
}

/* mediaquery css ends */

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 250px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
  background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
  background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
  background-color: #70C1B3;
}
.message-box {
	padding: 50px;
	border: 2px dashed #ccc;
	/*line-height: 30px;*/
	max-width: 100%;
	flex: 100% 1;
}
.message-box i {
	font-size: 18px;
	color: #000;
	margin-right: 15px;
}

.message-box p,
.message-box small {
	color: #777;
}

.price-box {
	padding: 20px;
	display: inline-flex;
	flex-flow: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	list-style: none;
}
.price-box li {
	width: 49%;
	color: #000;
	font-size: 16px;
	font-weight: 600;
}
ul.price-box li::before {
	display: none;
}
ul.price-box li:nth-child(odd) {
	text-align: left;
	padding-left: 20px;
}

.btn-search {
	height: auto;
}
.message-box label {
	margin-bottom: 0px;
	font-size: 18px;
	color: #000;
	font-weight: normal;
	display: block;
}

.register-form .form-control {
	border: 0px;
	margin: 0px;
	width: calc(100% - 30px);
	border-bottom: 1px solid #c6c6c6;
	padding: 0;
	box-shadow: none;
	font-size: 18px;
	height: 55px;
	color: #000;
	font-family: "Chronicle Text G1";
}
.register-form .form-control::-webkit-input-placeholder {
	color: #c6c6c6;
}
.register-form .form-control::-moz-placeholder {
	color: #c6c6c6;
}
.register-form .form-control:-ms-input-placeholder {
	color: #c6c6c6;
}
.register-form .form-control::-ms-input-placeholder {
	color: #c6c6c6;
}
.register-form .form-control::placeholder {
	color: #c6c6c6;
}
.register-form .form-control:hover .register-form .form-control:focus {
	border: 0px;
	box-shadow: 0px;
	outline: 0px;
}
.equal-space {
	margin: 50px 0px;
}
.form-check .form-check-input {
	padding: 5px;
	height: 20px;
	width: 19px;
}
.form-check-label {
	margin-left: 10px;
}
.form-group {
	margin-bottom: 0rem;
	margin: 15px 0px;
}

.order-summary h3 {
	margin-bottom: 20px;
	padding-left: 16px;
}
.total-order-summary {
	display: flex;
	justify-content: space-between;
	width: 80%;
}
.total-detail {
	padding-left: 20px;
}
.total-mrp {
	padding-right: 20px;
}
.total-summary {
	border: 1px solid #d9d9d9;
	padding: 10px 0;
	margin-top: 10px;
}
/* mediaquery css starts */
@media (max-width: 1024px) {
	.order-summary h3 {
		margin-top: 45px;
	}
	.total-order-summary {
		width: 100%;
	}
	.order-summary.col-md-4.col-sm-12 {
		flex: 100% 1;
		max-width: 100%;
	}
}
@media (max-width: 768px) {
	.equal-space {
		margin: 0;
	}
}
@media (max-width: 767px) {
	.message-box {
		margin: auto;
		padding: 20px;
		max-width: 100%;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.register-form .col-sm-8 {
		flex: 100% 1;
		max-width: 100%;
	}

	.message-box label {
		font-size: 16px;
	}
}
/* mediaquery css ends */

.regthankyou h2 {
	padding: 0;
}

@media (max-width: 768px) {
	.message-box {
		margin: 40px 20px;
		padding: 20px 20px;
	}
	.regthankyou h3 {
		font-size: 20px;
	}
}

.exporderreview h6 {
	color: #000;
	font-size: 18px;
	margin: 15px 0;
}
.reservation-detail {
	display: flex;
	margin-top: 10px;
	align-items: center;
	flex-wrap: wrap;
}
.reservation-detail .breakdown-wrapper {
	flex: 1 1;
}
.reservation-detail .breakdown-wrapper p {
	margin-left: 15px;
	line-height: normal;
	display: inline-block;
}
.orderreviewsummary h4 {
	padding-left: 15px;
	margin-bottom: 15px;
}
.orderreviewedit a {
	font-size: 16px;
	color: #000;
	font-weight: 600;
}
.orderreviewedit .left-angle {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
.orderreviewedit {
	padding: 0 100px;
}
/* mediaquery css starts */
@media (max-width: 1366px) {
	.orderreviewedit a {
		padding-left: 20px;
	}
}
@media (max-width: 1280px) {
	.orderreviewedit a {
		padding-left: 80px;
	}
}
@media (max-width: 1024px) {
	.orderreviewedit {
		padding: 0;
	}
	.orderreviewedit a {
		padding-left: 40px;
	}
}
@media (max-width: 767px) {
	.orderreviewsummary {
		margin-top: 30px;
	}
	.orderreviewsummary h4 {
		padding-left: 0;
	}
	.orderreviewedit a {
		padding-left: 20px;
	}
	button.cmn-btn.no-print.send-order.btn.btn-primary {
		margin: 10px auto;
	}
}
/* mediaquery css ends */

.Wishlisthold span {
	font-size: 18px;
	color: #a99f9f;
	text-align: center;
	display: block;
	margin-bottom: 20px;
}

.WishListEmpty {
	margin: 100px auto 100px;
}

.expcard .card-text > span {
	text-align: left;
}
.abtbanner img {
	width: 100%;
}
.no-space {
	padding: 0;
}
.visible-tab {
	display: none;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.aboutbanner {
		height: 410px;
	}
}
@media (max-width: 1366px) {
	.aboutbanner {
		height: 350px;
	}
}
@media (max-width: 1280px) {
	.aboutbanner {
		height: 300px;
	}
}
@media (max-width: 1024px) {
	.aboutbanner {
		height: 250px;
	}
}
@media (max-width: 768px) {
	.aboutbanner {
		height: 200px;
	}
	.visible-tab {
		display: block;
	}
}
@media (max-width: 767px) {
	.aboutbanner {
		height: 155px;
	}
	.visible-tab {
		display: none;
	}
}
/* mediaquery css ends */

.about-text p {
	font-size: 16px;
	color: #000;
	margin-bottom: 20px;
}

.about-text h3 {
	margin: 10px 0px;
	font-size: 22px;
}

.checkcircle {
	font-size: 30px;
	margin-left: 10px;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
	background: #50bc20;
	border-radius: 100%;
	border: none;
	box-shadow: none;
}
.solidicon {
	font-size: 20px;
	color: #000;
	display: inline-block;
	vertical-align: middle;
	border-radius: 100%;
	margin-right: 10px;
	margin-top: 2px;
}
.textequalspace {
	padding-top: 25px;
}
.reservation-map p {
	font-size: 18px;
	line-height: 24px;
	color: #8e8e8e;
}
.txtblack {
	color: #000;
}
.reservation-map {
	width: auto;
}
h3.txt-orange.textequalspace {
	padding-top: 0;
}
/* meadiaquery starts */
@media (max-width: 768px) {
	.reservation-map .col-md-6 {
		width: 100%;
		flex: 100% 1;
		max-width: 100%;
	}
	.reservation-map-img {
		padding-top: 30px;
	}
}
/* mediaquery ends */

.date-selector input {
	border: 0;
	background: transparent;
	padding: 0 15px;
}
.sort-date {
	display: flex;
	justify-content: flex-end;
}
.date-selector {
	border: 1px solid #d9d9d9;
	position: relative;
}
.sort-date h6 {
	display: inline-block;
	vertical-align: middle;
	padding: 20px 0;
	margin-right: 20px;
}
.sort-date .DayPicker {
	width: 100%;
}
.blogsdata {
	margin: 35px 0;
}
.card.dash-box {
	border: 2px dashed #d9d9d9;
	padding: 30px;
}
.date-tite {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
}
.blog .card-title {
	font-size: 30px;
	margin: 20px 0;
}
.blog .card-text {
	margin: 0;
}
.blog {
	margin-bottom: 30px;
}
.blogcomment {
	display: flex;
	font-size: 18px;
}
.blog .card-text.blogBy {
	border-right: 1px solid #d9d9d9;
	margin-right: 10px;
	padding-right: 10px;
}
.date-tite .card-text {
	color: #8a8a8a;
}
.showmore-blog {
	margin-bottom: 150px;
}

/* mediaquery css starts */
@media (max-width: 1366px) {
	.blog .card-title {
		font-size: 24px;
	}
}
@media (max-width: 768px) {
	.col-md-4.blog {
		max-width: 100%;
		flex: 100% 1;
	}
	.showmore-blog {
		margin-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.sort-date {
		justify-content: center;
	}
	.blog .card-title.h5 {
		font-size: 20px;
	}
	.blogcomment {
		font-size: 16px;
	}
	.showmore-blog {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */

.icon-angle-down.profile-dropdown.dropdown {
	background: #f15b3b;
	margin-top: -60px;
	margin-right: 0;
}
.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary {
	background: #f15b3b;
	color: #fff;
	height: 60px;
	margin: 0;
	margin-top: 50px;
	padding-left: 20px;
	padding-right: 50px;
}
.profile-dropdown.dropdown.icon-angle-down .dropdown-toggle::after {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzM5NkY5MTlFNUNGMTFFOUFFN0E5Njk0MUQ2RjQxQTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzM5NkY5MUFFNUNGMTFFOUFFN0E5Njk0MUQ2RjQxQTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMzk2RjkxN0U1Q0YxMUU5QUU3QTk2OTQxRDZGNDFBNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzk2RjkxOEU1Q0YxMUU5QUU3QTk2OTQxRDZGNDFBNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvAIregAAACKSURBVHjapNNNCoAgEAVgbduZojvokQLrNh0uWtgie5qCmDmZA8+FPx+DMNwYw1A9srO2ckaHRSIbMjVgyhuCocMROcxdynZcmcW/1cgQNuVPNMaE+77osBZ9YClYg2axHJiicw32BgZUZ9AiVgJzKIlRYIqS2BcwRknMhvvRo8pO04ms1MVLgAEAWZ9WFa7MUuMAAAAASUVORK5CYII=) no-repeat;
	right: -20px;
	background-position: right center;
	position: relative;
}
.profile-dropdown.icon-angle-down .dropdown-menu {
	background: #fff;
	border: 1px solid #f15b3b;
}
.navbar-light .navbar-nav .profile-dropdown .dropdown-menu a:first-child {
	padding-left: 25px;
}
.profile-dropdown a.dropdown-item {
	border-bottom: 1px solid #d1d1d1;
	width: 80%;
	margin: 0 auto;
}
.profile-dropdown a.dropdown-item:last-child {
	border-bottom: 0;
}
.profile-dropdown.icon-angle-down .dropdown-item.active,
.profile-dropdown.icon-angle-down .dropdown-item:active {
	background-color: transparent;
}
/* .profile-dropdown:hover .dropdown-menu {
	display: block;
} */

/* mediaquery css starts */
@media (max-width: 768px) {
	.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary,
	.icon-angle-down.profile-dropdown.dropdown {
		margin-top: 0;
	}
	.icon-angle-down.profile-dropdown.dropdown {
		background: transparent;
		margin: 0 auto;
	}
}
@media (max-width: 767px) {
	.icon-angle-down.profile-dropdown.dropdown,
	.profile-dropdown.dropdown button.dropdown-toggle.btn.btn-primary {
		margin-top: 0;
	}
	.profile-dropdown.dropdown.icon-angle-down .dropdown-toggle:after {
		top: 60%;
		background-position: right center;
	}
}
/* mediaquery css ends */

/* Global styles */
.navbar-light .navbar-nav .profile-dropdown .dropdown-menu a.dropdown-item {
	padding-left: 20px;
}
.navbar-light
	.navbar-nav
	.profile-dropdown
	.dropdown-menu
	a.dropdown-item:hover {
	color: #000;
}
button:focus {
	outline: 0;
	outline: 0;
}

.Newsdata {
	margin: 35px 0;
}
.News.col-md-3.col-12 {
	border-bottom: 1px solid #d9d9d9;
	margin-bottom: 50px;
	padding-bottom: 30px;
}
.showmore-News {
	margin-bottom: 150px;
}
.newstitle.card-text {
	font-size: 28px;
	line-height: 34px;
	margin-bottom: 5px;
}
.newsdate.card-text {
	color: #8a8a8a;
}
.brief.card-text {
	font-size: 18px;
	color: #8a8a8a;
	margin-top: 20px;
}

/* mediaquery css starts */
@media (max-width: 1440px) {
	.newstitle.card-text {
		font-size: 24px;
		line-height: 30px;
	}
}
@media (max-width: 768px) {
	.News.col-md-3.col-12 {
		flex: 100% 1;
		max-width: 100%;
	}
	.showmore-News {
		margin-bottom: 100px;
	}
}
@media (max-width: 767px) {
	.newstitle.card-text {
		font-size: 22px;
	}
	.News.col-md-3.col-12 {
		margin-bottom: 35px;
		padding-bottom: 20px;
	}
	.showmore-News {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */

.contact-detail a {
	font-size: 16px;
	display: block;
	color: #000;
	text-align: left;
}
.contact-icons {
	margin-right: 15px;
}
.ContactUs {
	display: flex;
	width: 100%;
}
.contact-detail {
	width: 50%;
}
.contact-detail p {
	font-size: 16px;
	text-align: left;
	color: #000;
}
.text-center.message-box.ContactUs.col-sm-12 {
	padding: 50px 200px;
	margin-bottom: 150px;
}

/* mediaquery css starts */
@media (max-width: 1024px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px 100px;
	}
}
@media (max-width: 768px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px;
	}
}
@media (max-width: 767px) {
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 50px;
	}
	.ContactUs {
		display: block;
	}
	.contact-detail {
		width: 100%;
	}
	.text-center.message-box.ContactUs.col-sm-12 {
		padding: 20px;
	}
	.contact-detail a {
		margin-bottom: 10px;
	}
}
/* mediaquery css ends */

.search-wrap {
	margin-bottom: 150px;
}
.search-wrap span {
	font-size: 18px;
	color: #8a8a8a;
	display: block;
	text-align: center;
	margin-bottom: 60px;
}
.search-div h6 {
	font-size: 18px;
	color: #8a8a8a;
	margin-bottom: 15px;
}
.search-div p {
	font-size: 26px;
	color: #000;
}
.search-div {
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

/* mediaquery css starts */
@media (max-width: 768px) {
	.search-div p {
		font-size: 20px;
	}
}
/* mediaquery css ends */

.galleryalbum img {
	width: 100%;
}

.galleryalbum .slick-prev {
	left: 0px;
	z-index: 1;
	background: white;
	width: 50px;
	height: 30px;
}
.galleryalbum .slick-next {
	right: 0;
	background: white;
	width: 50px;
	height: 30px;
}
.galleryalbum .slick-next:before,
.galleryalbum .slick-prev:before {
	font-size: 20px;
	line-height: 1;
	opacity: 1;
	color: #000;
}
.modal-content {
	padding: 10px;
}
.card-body {
	padding-left: 0px;
}
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.6);
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.expcard.gallerywrap {
		padding-bottom: 20px;
	}
}
/* mediaquery css ends */

.termspolicieswrap {
	margin-bottom: 150px;
}
.termspolicies h3 {
	margin-bottom: 20px;
	font-size: 22px;
}
.termspolicies p {
	color: #000;
	margin-bottom: 10px;
	font-size: 16px;
}
.top-space {
	margin-top: 20px;
}
.termspolicies ul {
	list-style-type: none;
}
.termspolicies ul li {
	margin-left: 30px;
	margin-bottom: 10px;
}
.termspolicies ul li::before {
	content: "\2022";
	color: #f15b3b;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}
/* mediaquery css starts */
@media (max-width: 767px) {
	.termspolicies p,
	.termspolicies ul li {
		font-size: 16px;
	}
	.termspolicieswrap {
		margin-bottom: 50px;
	}
}
/* mediaquery css ends */



.cmn-btn.login-btn {
	margin-right: 20px;
}
.loginpage .d-flex {
	justify-content: space-between;
}
.regac.form-group a.forgotpasswordbtn {
	padding-left: 5px;
	margin-left: 0;
}
.errormsg {
	font-size: 16px;
	color: #000;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}

/* media query starts */
@media (max-width: 768px) {
	.loginpage .d-flex {
		display: block !important;
	}
}
/* media query ends */

.forgotpasswordpage h3 {
	margin-bottom: 20px;
}
.forgotpasswordpage p {
	color: #000;
}


.cmn-btn.top-space {
	margin-top: 25px;
}
.errormsg {
	font-size: 16px;
	color: #000;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}
.regthankyou h2 {
	padding: 0;
}

@media (max-width: 768px) {
	.message-box {
		margin: 40px 20px;
		padding: 20px 20px;
	}
	.regthankyou h3 {
		font-size: 20px;
	}
}

.logoutpage a {
	display: inline-block;
	margin-right: 20px;
}
.logoutpage p {
	color: #000;
	font-size: 22px;
	margin: 15px 0;
}






.loader-wrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.8)
}
.order-list-box {
	display: flex;
	padding-bottom: 30px;
	border-bottom: 1px solid #ded3d3;
	margin-bottom: 30px;
}
.order-list-box:last-child {
	padding-bottom: 0px;
	border-bottom: 0px;
	margin-bottom: 0px;
}
.order-list-box .col-sm-6,
.order-list-box .col-md-6 {
	padding-left: 0px;
}

.order-list-box .card-title {
	margin-top: 5px;
}
.order-text {
	color: #777;
}
.order-text .list-group {
	flex-direction: row;
}
.order-text .list-group .list-group-item {
	border: none;
	padding: 0.35rem 1.25rem;
}
.order-text .list-group .list-group-item:first-child {
	padding-left: 0px;
	margin-top: 0px;
}
.order-text .bookinfinfo.list-group .list-group-item {
	color: #000;
	border-right: 1px solid #d9d9d9;
	margin-top: 15px;
}
.order-text .bookinfinfo.list-group .list-group-item:last-child {
	border-right: none;
}
.exp-infor {
	display: flex;
	flex-wrap: wrap;
}
.exp-infor .icon-wrapper {
	align-self: flex-start;
}
.exp-infor .breakdown-wrapper {
	flex: 1 1;
}
.user-details {
	padding: 3px;
	padding-top: 10px;
	justify-content: space-between;
}
.user-details p {
	line-height: 34px;
	padding-bottom: 6px;
}
.user-details svg {
	color: #000;
	margin-right: 15px;
	margin-top: -5px;
}

.details-total b {
	min-width: 150px;
}

.details-total b:nth-child(2)::before {
	content: "";
	position: absolute;
	height: 25px;
	width: 1px;
	background: #d9d9d9;
	margin-left: -20px;
	margin-top: 3px;
}

.NoOrderToShow {
	margin: auto;
}

/* Styling for dropdown */
.searchfilter.customDropdown .labelText {
	color: #999;
}
.searchfilter.customDropdown .icon-angle-down.dropdown {
	margin-right: 0;
}
.searchfilter.customDropdown .icon-angle-down .dropdown-toggle {
	margin: 0.2rem 0;
	height: 3rem;
	padding: 0.5rem 1rem;
}
.searchfilter.customDropdown .icon-angle-down .btn-primary,
.searchfilter.customDropdown .icon-angle-down.show .btn-primary {
	border: 1px solid #f15b3b;
	color: #f15b3b;
}
.searchfilter.customDropdown .icon-angle-down .btn-primary:hover,
.searchfilter.customDropdown .icon-angle-down.show .btn-primary:hover {
	color: #f15b3b;
	border: 1px solid #f15b3b;
}
.searchfilter.customDropdown
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled).active,
.searchfilter.customDropdown
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled):active,
.searchfilter.customDropdown
	.icon-angle-down
	.show
	> .btn-primary.dropdown-toggle {
	color: #f15b3b;
	background-color: #d9d9d9;
	border: 1px solid #f15b3b;
}
.searchfilter.customDropdown .icon-angle-down .dropdown-item {
	color: #444;
}
.order-status-info .list-group-item {
	display: flex;
}
.order-status-info .status-val {
	margin-left: 1rem;
	color: #000;
	font-weight: 600;
}
.order-status-info .status-subval {
	margin-left: 0.5rem;
	font-weight: 400;
	color: #f15b3b;
}
.action-wrapper {
	display: flex;
}
.action-wrapper .cmn-btn.card-link {
	display: inline-block;
	margin: 0;
}
.action-wrapper .cmn-btn-inverse.btn.btn-primary {
	margin-left: auto;
}
.d-flex.justify-content-end.mb-3.col-sm-12 {
	justify-content: center !important;
}
/* mediaquery css starts */
@media (max-width: 1444px) {
	.order-list-box .card-title {
		font-size: 24px;
	}
}
@media (max-width: 1024px) {
	.user-details {
		display: block !important;
	}
	.order-list-box .card-title {
		font-size: 20px;
	}
	.order-text .list-group .list-group-item {
		font-size: 13px;
	}
}
@media (max-width: 768px) {
	.order-list-box {
		display: inline;
		padding: 30px 0px;
		margin: 0;
	}
	.order-list-box:first-child {
		padding: 0px 0px 20px 0px;
	}
	.order-list-box .col-md-5,
	.order-list-box .col-md-7 {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
	}
	.order-text {
		padding: 20px;
	}

	.order-list-box img {
		width: 100%;
	}
	.order-text {
		margin-top: 20px;
	}
	.order-text .list-group .list-group-item {
		font-size: 16px;
	}
}
@media (max-width: 767px) {
	.order-text .card-title.h5 {
		font-size: 24px;
	}
	.order-text .list-group .list-group-item {
		border: none;
		padding: 8px 0px;
	}
	.order-text .list-group {
		display: block;
	}
	.order-text .bookinfinfo.list-group .list-group-item {
		border-bottom: 1px solid #d9d9d9;
		margin-top: 0;
	}
	.order-text .list-group .list-group-item {
		font-size: 14px;
	}
	.d-flex.justify-content-end.mb-3.col-sm-12 {
		justify-content: center !important;
	}
	.icon-angle-down .btn-primary {
		min-width: 160px;
	}
}

/* mediaquery css ends */

.order-booking {
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 30px;
	padding-left: 0;
	margin-bottom: 30px;
}
.orderdetaillist {
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
}
.booking-contact li {
	font-weight: 600;
	display: flex;
}
.order-summary {
	color: #000;
	font-weight: 600;
	padding-left: 2.5rem;
}
.order-summary h2 {
	color: #000;
	font-weight: 600;
}
.card-booking h3 {
	padding: 10px 0px;
}
.booking-contact {
	padding: 0px 40px;
	border-right: 1px solid #d9d9d9;
	margin: 20px 0px;
}
.booking-contact:first-child {
	padding-left: 0px;
}

.booking-contact li {
	font-weight: 600;
	font-size: 14px;
}

.booking-contact li.title {
	font-size: 18px;
}
.booking-contact li svg {
	margin-right: 10px;
}
.title {
	font-size: 20px;
	padding-bottom: 5px;
}
.order-booking h5:not(:last-of-type) {
	margin-bottom: 1rem;
}
.order-booking h5 span {
	color: #b0b0b0;
}
.order-booking h5 .hold-status {
	margin-left: 0.5rem;
	font-weight: 400;
	color: #f15b3b;
}
.no-space {
	padding: 0px;
}
.print-btn {
	cursor: pointer;
}

.info-breakdown {
	display: flex;
	margin: 5px 0;
}
.info-breakdown .icon-wrapper {
	margin: -3px 5px 0 0;
}
.info-breakdown .info-wrapper {
	display: flex;
	flex-wrap: wrap;
}
.info-breakdown .info-wrapper > .info-item {
	margin-right: 1rem;
}
.cancel-wrapper {
	display: flex;
	justify-content: flex-end;
}
/* mediaquery css starts */

@media (max-width: 1024px) {
	.order-summary {
		/* margin-left: 1em; */
	}
	.booking-contact {
		padding: 0px 10px;

		margin: 20px 0px;
	}

	.booking-contact li {
		font-weight: 600;
		font-size: 14px;
	}
	.booking-contact li.title {
		font-size: 16px;
	}
	.order-summary h3 {
		margin-top: 0;
	}
}
@media (max-width: 768px) {
	.order-booking,
	.order-booking .d-flex {
		display: block !important;
	}
	.booking-contact {
		padding: 0px;
		border: 0px;
	}
	.orderdetaillist {
		margin-top: 0px;
		display: inline !important;
	}

	.orderdetaillist .col-md-3,
	.orderdetaillist .col-md-4 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-summary {
		margin-left: 80px;
		max-width: 100%;
		margin: auto;
		float: none;
		padding: 0;
		margin-bottom: 30px;
	}
	.order-booking h5 {
		font-size: 16px;
	}
	.card-booking h3 {
		font-size: 24px;
	}
	.user-details p {
		font-size: 14px;
	}
	.message-box {
		margin: 60px 20px;
	}
	.print-btn {
		margin-bottom: 20px;
	}
}
@media (max-width: 767px) {
	.cancel-wrapper {
		justify-content: flex-start;
	}
}
/* mediaquery css ends */

/* Media queries for Print screen */
@media print {
	.print-btn {
		display: none !important;
	}
	.no-print {
		display: none !important;
	}
	.orderdetaillist .print-inline-blk {
		display: inline-block;
		max-width: -webkit-max-content;
		max-width: -moz-max-content;
		max-width: max-content;
	}
	.orderdetaillist .print-inline-blk:not(:last-of-type) {
		margin-right: 2rem;
	}
}

.changepasswordpage h3 {
	margin-bottom: 20px;
}
.changepasswordpage p {
	color: #000;
}


.regthankyou h2 {
	padding: 0;
}

@media (max-width: 768px) {
	.message-box {
		margin: 40px 20px;
		padding: 20px 20px;
	}
	.regthankyou h3 {
		font-size: 20px;
	}
}

.faq h4,
.faq h4 a {
	font-size: 18px;
	color: #000;
}
.faq {
	border-bottom: 1px solid #ccc;
}
.faq h4 {
	margin-bottom: 30px;
}
.faqaccordian {
	margin: 80px 0;
}
.faqaccordian .card {
	margin-top: 30px;
}
.faqaccordian .card-header,
.faqaccordian .card-header a {
	font-size: 18px;
	color: #000;
	background-color: transparent;
	border-bottom: 0;
	padding: 0.75rem 0;
}
.faqaccordian .card-body p,
.faqaccordian .card-body li {
	font-size: 16px;
	margin-bottom: 20px;
	color: #777;
}
.faqaccordian .accordion > .card {
	border-bottom: 1px solid #ccc !important;
}
.faqaccordian .accordion > .card .card-header {
	margin-bottom: 10px;
}
.account-qstn.faqaccordian {
	margin: 0;
}
.account-qstn.faqaccordian .card-body p {
	margin-bottom: 0;
}
.account-qstn.faqaccordian .card {
	margin-top: 20px;
}
.account-qstn.faqaccordian.refund {
	margin-top: 50px;
}

.faqaccordian.ac-qstn .card-header > a:before {
	float: right !important;
	font-family: FontAwesome;
	content: "\f067";
	padding-right: 5px;
	font-size: 16px;
}
.faqaccordian.ac-qstn .collapse.show::before {
	position: absolute;
	height: 40px;
	width: 40px;
	content: "";
	font-family: FontAwesome;
	float: right !important;
	content: "\f068";
	top: 25px;
	right: -22px;
	background: #fff;
}

.faqaccordian .card-header a {
	display: block;
}
/* media query starts */
@media (max-width: 768px) {
	.faqaccordian .card-header,
	.faqaccordian .card-header a,
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		font-size: 20px;
	}
	.faqaccordian .card-header {
		padding: 0.75rem 1.25rem;
	}
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		padding: 0 1.25rem;
	}
	.faqaccordian.ac-qstn .collapse.show::before {
		right: 0;
	}
}
@media (max-width: 767px) {
	.faq h4,
	.faq h4 a {
		font-size: 20px;
	}
	.faqaccordian h3.txt-orange {
		font-size: 22px;
	}
	.faqaccordian .card-header {
		padding: 0.75rem 0;
	}
	.faqaccordian .card-body p,
	.faqaccordian .card-body li {
		padding: 0;
	}
	.faqaccordian.ac-qstn .collapse.show::before {
		right: -22px;
	}
}
/* media query ends */

.multicheckbox {
	display: inline-block;
	margin-right: 30px;
}
.plan-experience .message-box label {
	margin-bottom: 10px;
	display: block;
}
.plan-experience .icon-angle-down {
	border: 1px solid #c6c6c6;
	width: 30%;
}
.plan-experience .icon-angle-down .btn-primary {
	height: auto;
	margin: 0;
	padding: 10px;
}
.planexperienceform {
	margin-top: 20px;
}
.plan-experience
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled).active,
.plan-experience
	.icon-angle-down
	.btn-primary:not(:disabled):not(.disabled):active,
.plan-experience .icon-angle-down .show > .btn-primary.dropdown-toggle {
	background: transparent;
}
.plan-experience .icon-angle-down .btn-primary::before {
	content: "*";
	font-size: 18px;
	position: relative;
	top: 4px;
	left: 0;
	line-height: 0;
	margin-right: 5px;
	color: #c6c6c6;
	font-family: serif;
}
.plan-experience .form-group {
	margin: 15px 0 25px;
}
/* .plan-experience .form-group .errorMsg {
	position: absolute;
} */
.plan-experience .message-box .nospace {
	margin-bottom: 0;
}
.planexperienceform label.form-check-label {
	color: #908282;
	margin-bottom: 0;
	position: relative;
	top: 2px;
}

/* mediaquery css starts */
@media (max-width: 1024px) {
	.plan-experience .icon-angle-down {
		width: 35%;
	}
}
@media (max-width: 767px) {
	.multicheckbox {
		display: block;
	}
	.plan-experience .icon-angle-down {
		width: 100%;
	}
	.plan-experience .register-form .form-control {
		width: 100%;
	}
	.plan-experience .message-box {
		margin: 40px 5px;
		padding: 20px 10px;
	}
	.plan-experience .register-form .form-control::-webkit-input-placeholder {
		font-size: 10px;
	}
	.plan-experience .register-form .form-control::-moz-placeholder {
		font-size: 10px;
	}
	.plan-experience .register-form .form-control:-ms-input-placeholder {
		font-size: 10px;
	}
	.plan-experience .register-form .form-control::-ms-input-placeholder {
		font-size: 10px;
	}
	.plan-experience .register-form .form-control::placeholder {
		font-size: 10px;
	}
	.plan-experience .mx-auto.register-form.col-md-10.col-sm-12 {
		padding: 0 10px;
	}
	/* .plan-experience   .register-form .form-row{
    margin-bottom: 45px;;
  } */
}
/* mediaquery css ends */


@charset "utf-8";

/*====limited reset====*/
html,
body,
div,
section,
article,
aside,
header,
hgroup,
footer,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
time,
span,
em,
strong,
img,
ol,
ul,
li,
figure,
canvas,
video {
	margin: 0;
	padding: 0;
	border: 0;
}

/*====html5 display rule====*/
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
menu,
nav,
section,
summary {
	display: block;
}

/*====common css====*/
html,
body {
	height: 100%;
	width: 100%;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
	outline: 0;
	color: #555555;
}

a:hover,
a:focus {
	text-decoration: none;
	color: #555555;
	outline: none;
}

/* @font-face {
    font-family: 'Chronicle Text G1';
    src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'), url('../fonts/CenturyGothic.woff') format('woff'), url('../fonts/CenturyGothic.ttf') format('truetype'), url('../fonts/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
	font-family: "Chronicle Text G1";
	src: url("fonts/ChronicleTextG1-Roman.eot");
	src: url("fonts/ChronicleTextG1-Roman.eot?#iefix") format("embedded-opentype"),
		url("fonts/ChronicleTextG1-Roman.woff2") format("woff2"),
		url("fonts/ChronicleTextG1-Roman.woff") format("woff"),
		url("fonts/ChronicleTextG1-Roman.ttf") format("truetype"),
		url("fonts/ChronicleTextG1-Roman.svg#ChronicleTextG1-Roman") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Chronicle Text G1 Semi";
	src: url("fonts/ChronicleTextG1-Semi.eot");
	src: url("fonts/ChronicleTextG1-Semi.eot?#iefix") format("embedded-opentype"),
		url("fonts/ChronicleTextG1-Semi.woff2") format("woff2"),
		url("fonts/ChronicleTextG1-Semi.woff") format("woff"),
		url("fonts/ChronicleTextG1-Semi.ttf") format("truetype"),
		url("fonts/ChronicleTextG1-Semi.svg#ChronicleTextG1-Semi") format("svg");
	font-weight: 600;
	font-style: normal;
} */
body {
	font-family: "Tenor Sans", sans-serif;
	font-size: 16px;
	line-height: 26px;
	margin: 0px;
	padding: 0px;
	color: #323232;
	-webkit-font-smoothing: subpixel-antialiased;
	overflow-x: hidden;
}
::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}
::-webkit-scrollbar-track-piece {
	background-color: #fff;
}
::-webkit-scrollbar-thumb:vertical {
	height: 30px;
	background-color: #f15b3b;
}
.submit-btn {
	background: #323232 none repeat scroll 0 0;
	border: medium none;
	color: #f15b3b;
	display: block;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
	width: 100%;
	text-transform: uppercase;
	text-decoration: none;
}

.form-control {
	background-color: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 0;
	box-shadow: none;
	color: #000;
	display: block;
	font-size: 16px;
	height: 60px;
	padding: 6px 25px;
	width: 100%;
}
h2 {
	font-size: 40px;
	color: #000000;
	padding: 60px 0;
	position: relative;
	text-align: center;
}
.line {
	position: absolute;
	z-index: 2;
	content: "";
	width: 10%;
	bottom: 40px;
	height: 2px;
	background: #f15b3b;
	left: 0;
	right: 0;
	margin: 0 auto;
	-webkit-animation: drawline 0.75s 1 forwards;
	animation: drawline 0.75s 1 forwards;
}
@-webkit-keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}
@keyframes drawline {
	0% {
		width: 0;
	}
	100% {
		width: 10%;
	}
}

card {
	border: none;
	border-radius: 0px;
}
.card-title {
	font-size: 28px;
	color: #000;
	margin-top: 20px;
	text-transform: none;
}
.card-text {
	font-size: 18px;
	color: #000;
	margin-bottom: 20px;
}
.card-text.limit-line-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 26px; /* fallback */
	max-height: 52px; /* fallback */
	/* -webkit-line-clamp: 2;  */
	-webkit-box-orient: vertical;
}

.price.card-text {
	font-size: 24px;
	color: #f15b3b;
	margin-bottom: 30px;
}

.border-btn {
	border: 1px solid #000;
	padding: 5px 30px;
	margin: 10px 0 0px;
	display: table;
	border-radius: 0px;
	background: transparent;
	color: #000;
	border-radius: 0;
}
.border-btn:hover {
	background: #000;
	color: #fff;
	outline: none;
	border-color: #000;
}
.border-btn:focus {
	outline: none;
}

.card-img-top {
	border-radius: 0px;
}
.show-more {
	color: #000;
	font-size: 20px;
}

.show-more i {
	padding-left: 20px;
}

.expcard {
	padding-bottom: 80px;
}
.card {
	border: none;
	border-radius: 0;
}
.container-grid {
	padding: 0 35px;
}
.navbar-light .navbar-nav a {
	color: #000;
}
.btn:focus,
.btn:visited {
	outline: none;
}
.clear {
	clear: both;
}

.form-control:focus,
.btn.focus,
.btn:focus {
	box-shadow: none;
}
.txt-orange {
	color: #f15b3b;
}
.cmn-btn {
	border: 1px solid transparent;
	padding: 5px 30px;
	margin: 10px 0 0px;
	display: table;
	border-radius: 0px;
	background: #f15b3b;
	color: #fff;
	border-radius: 0;
}
.cmn-btn:hover {
	color: #f15b3b;
	background: #fff;
	border: 1px solid #f15b3b;
}
h4 {
	font-size: 30px;
	line-height: 35px;
	color: #f15b3b;
}
option {
	padding: 10px;
	line-height: 26px;
	font-size: 18px;
}
.card-body {
	padding: 10px 0;
}
.err_text,
.err_text a {
	color: red !important;
	font-size: 16px;
	margin-top: 10px;
}
.err_text a:hover {
	color: #f15b3b;
	text-decoration: underline;
}
.form-text {
	font-size: 16px;
	color: red;
}
.hidden-xs {
	display: block;
}
.visible-xs {
	display: none;
}
.fixed-top {
	background: #fff;
}
.navbar-light .navbar-brand {
	margin-left: 30px;
}
.logreg.navbar-nav {
	margin-right: 30px;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.left-menu {
		padding-left: 50px;
	}
	.navbar-light .navbar-nav a {
		padding: 15px 15px;
	}
	.card-title {
		font-size: 30px;
	}
	h2 {
		font-size: 54px;
		line-height: 64px;
	}
}
@media (max-width: 1280px) {
	form#topsearch {
		width: 245px;
	}
	form#topsearch .form-control {
		padding: 25px;
	}
	form#topsearch .submit-btn {
		width: 10%;
	}
	h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 1024px) {
	.left-menu {
		padding-left: 0;
	}
	form#topsearch {
		width: 170px;
	}
	.navbar-light .navbar-nav a {
		padding: 15px 10px;
		font-size: 16px;
	}
	.arrorright {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: -37px;
	}
	.navbar-brand img {
		width: 82%;
	}
	.rega {
		margin-left: 5px;
	}
	h2 {
		font-size: 46px;
		line-height: 55px;
	}
	#home-journey .item span p {
		color: #fff;
		font-size: 14px;
		line-height: 20px;
	}
	#home-journey .item span {
		padding: 15px 15px;
	}
	.card-title {
		font-size: 24px;
	}
	.price.card-text {
		font-size: 18px;
	}
	.card-text {
		font-size: 14px;
	}
	.card-text.limit-line-2 {
		line-height: 20px;
		max-height: 60px;
	}
}
@media (max-width: 768px) {
	.card-title.h5 {
		font-size: 30px;
	}
	.price.card-text {
		font-size: 24px;
	}
	a.border-btn.btn.btn- {
		font-size: 18px;
	}
	.card-text {
		font-size: 21px;
	}
	.navbar-light .navbar-toggler {
		color: #f15b3b;
		border-color: transparent;
	}
	.navbar-light .navbar-nav a:first-child {
		padding-left: 0;
	}
	.navbar-light .navbar-nav a {
		padding: 5px 10px 5px;
		font-size: 18px;
		text-align: center;
	}
	.logreg {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: 0;
		padding: 5px 10px;
		margin: 15px auto 0;
		display: inline-block;
		width: 40%;
		text-align: center;
	}
	.logreg .navbar-light .navbar-nav a:last-child {
		padding-top: 0;
	}
	.navbar-collapse {
		padding: 50px 0;
	}
	.search-phn input.form-control.form-control {
		border: 0;
		width: 50%;
		padding: 0;
	}
	.search-phn.form-inline {
		width: 30%;
	}
	button.searchbtn.btn.btn-primary {
		background: transparent;
		border: 0;
	}
	form#topsearch {
		display: none !important;
	}
	form#topsearchphn {
		display: block !important;
	}
	h4 {
		font-size: 24px;
	}
	.navbar-light .navbar-brand {
		margin-left: 10px;
	}
	.logreg.navbar-nav {
		margin-right: 0;
	}
	.hidden-xs {
		display: none;
	}

	.card-text.limit-line-2 {
		line-height: 30px;
	}
}
@media (max-width: 767px) {
	.visible-xs {
		display: block;
	}
	h2 {
		font-size: 40px;
		line-height: 45px;
	}
	.card-text {
		font-size: 18px;
	}
	.card-body {
		padding: 10px 0;
	}
	.container-grid {
		padding: 0 0;
	}
	.navbar-light .navbar-toggler {
		color: #f15b3b;
		border-color: transparent;
	}
	.navbar-light .navbar-nav a:first-child {
		padding-left: 0;
	}
	.navbar-light .navbar-nav a {
		padding: 5px 0 5px;
		font-size: 18px;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
	.logreg {
		margin-left: 0;
	}
	.navbar-light .navbar-nav a.loginbtna {
		margin-top: 0;
		padding: 5px 10px;
		margin: 15px auto 0;
		display: inline-block;
		width: 40%;
		text-align: center;
	}
	.logreg .navbar-light .navbar-nav a:last-child {
		padding-top: 0;
	}
	.navbar-collapse {
		padding: 50px 0;
	}
	.search-phn input.form-control.form-control {
		border: 0;
		width: 50%;
		padding: 0;
	}
	.search-phn.form-inline {
		width: 30%;
	}
	button.searchbtn.btn.btn-primary {
		background: transparent;
		border: 0;
	}
	form#topsearch {
		display: none;
	}
	@-webkit-keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	@keyframes drawline {
		0% {
			width: 0;
		}
		100% {
			width: 20%;
		}
	}
	.navbar {
		padding: 15px 0;
	}
	a.border-btn {
		margin: 0 auto;
	}
}
/* mediaquery css ends */

/* Global styles */
form .suc_text {
	color: #48ac15;
	font-size: 16px;
	margin-top: 10px;
}
.go-back-link {
	margin-top: 10px;
	margin-bottom: 10px;
}
.go-back-link .link {
	font-weight: 600;
}
.go-back-link .link > svg {
	margin-right: 0.4rem;
	margin-bottom: -0.1rem;
}
.cmn-btn-inverse.btn.btn-primary {
	margin: 0;
	color: #f15b3b;
	background-color: #fff;
	border-color: #f15b3b;
	border-radius: 0;
}
.cmn-btn-inverse.btn.btn-primary:hover,
.cmn-btn-inverse.btn.btn-primary:focus {
	background-color: #f15b3b;
	color: #fff;
}
.cmn-btn-inverse.btn.btn-primary:disabled {
	cursor: not-allowed;
}
.cmn-btn-inverse.btn.btn-primary:disabled:hover,
.cmn-btn-inverse.btn.btn-primary:disabled:focus {
	background-color: #fff;
	color: #f15b3b;
}

/* Custom Alert */
.alert {
	position: fixed;
	margin-bottom: 0;
	background-color: #eee;
	top: 35%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	min-width: 25%;
	z-index: 1031;
	border-radius: 0;
	text-align: center;
}
.alert-dismissible .close {
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	padding: 0.5rem 1rem;
	outline: none;
}
.alert-custom {
	background-color: #e0e0e0;
	color: #fff;
}
.alert-suces {
	background-color: #39ae2b;
	color: #fff;
}
.alert-failr {
	background-color: #f15b3b;
	color: #fff;
}

/* Custom Modal */
.custom-modal {
	max-width: 26vw;
}
.custom-modal .modal-header {
	padding-bottom: 0;
}
.custom-modal .modal-title {
	font-size: 1.3rem;
	color: #f15b3b;
	font-weight: 500;
}
.custom-modal .modal-footer {
	padding-top: 0;
}

/* Media queries new */
@media (max-width: 1023px) {
	.custom-modal {
		max-width: 35vw;
	}
}
@media (max-width: 991px) {
	.custom-modal {
		max-width: 65vw;
	}
}
@media (max-width: 768px) {
	.go-back-link {
		margin-top: 50px;
	}
	.navbar {
		padding: 0;
	}
}
@media (max-width: 767px) {
	.custom-modal {
		max-width: 85vw;
		margin: 0 auto;
	}
}

