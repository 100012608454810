.section-05 {
	margin-bottom: 100px;
}
.section-05 .card[style] {
	margin-right: 20px;
	width: auto !important;
}
.section-05 .card-body {
	padding: 0px;
}
.section-05 .card .card-title {
	float: left;
}
.section-05 .card span {
	float: right;
	margin-top: 30px;
	opacity: 0.5;
}
.section-05 .card-text {
	font-size: 16px;
}

h2.heading {
	margin-bottom: 50px;
}
svg.svg-inline--fa.fa-arrow-left.fa-w-14.arrow-left {
	float: right;
	position: relative;
	top: -30px;
	right: 100px;
}
svg.svg-inline--fa.fa-arrow-right.fa-w-14.arrow-right {
	position: absolute;
	top: -30px;
	right: 40px;
}
.svg-inline--fa {
	font-size: 20px;
}
.container-grid-insta {
	padding-left: 40px;
}
.instaimg img {
	width: 100%;
	min-height: 296px;
	max-height: 296px;
	object-fit: cover;
}
/* meadiaquery starts */
@media (max-width: 1440px) {
	.section-05 .card .card-title {
		float: left;
		margin-bottom: 0;
	}
	.section-05 .card span {
		float: left;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.instaimg img {
		width: 100%;
		min-height: 200px;
		max-height: 200px;
	}
}
@media (max-width: 1024px) {
	.card-title.h5 {
		margin-top: 10px;
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	.section-05 .card[style] {
		margin-right: 0;
	}
	.section-05 {
		width: 100%;
		margin: 0 auto;
	}
	.section-05 .card span {
		display: block;
		float: none;
	}
	.section-05 .card .card-title {
		float: none;
	}
}
/* mediaquery ends */
