.f-logo {
	margin-top: 25px;
}
.footer-address {
	background: #f6f6f6;
	padding: 50px 55px 0px 55px;
	margin-top: -30px;
}
.navbar {
	padding-left: 0rem;
}
.navbar-light .navbar-nav a {
	padding: 15px 25px;
	font-size: 18px;
	color: #999898;
	line-height: 30px;
}
.navbar-light .navbar-nav a:first-child {
	padding-left: 0px;
}

.f-social .fa {
	font-size: 30px;
	color: #000;
	cursor: pointer;
}
.powerby {
	float: right;
	padding-top: 17px;
}
.f-right {
	float: left;
	margin-right: 20px;
	position: relative;
	padding: 0;
}
.f-right.navbar-light .navbar-nav a {
	color: #000;
	font-size: 16px;
	font-family: "Chronicle Text G1";
}
.bottomcopy {
	background: #fff;
}

.copy {
	padding-top: 25px;
}
.scoial-icon {
	font-size: 28px;
}

nav.f-social.navbar.navbar-expand.navbar-light {
	float: right;
}

.navbar-light .navbar-nav a .scoial-icon:hover.facebook {
	color: #3b5998;
	text-shadow: 0 0 15px #3b5998;
	transition: all 0.5s ease;
}

.navbar-light .navbar-nav a .scoial-icon:hover.twitter {
	color: #00aced;
	text-shadow: 0 0 15px #00aced;
	transition: all 0.5s ease;
}
.navbar-light .navbar-nav a .scoial-icon:hover.youtube {
	color: #c4302b;
	text-shadow: 0 0 15px #c4302b;
	transition: all 0.5s ease;
}
.navbar-light .navbar-nav a .scoial-icon:hover.linkdein {
	color: #0e76a8;
	text-shadow: 0 0 15px #0e76a8;
	transition: all 0.5s ease;
}
.f-right.navbar-light .navbar-nav a {
	padding: 15px 10px;
}

.f-right.navbar-light .navbar-nav a:hover {
	color: #f15b3b;
}
.powerby a {
	margin-left: 10px;
}
.displayflex {
	display: flex;
	justify-content: flex-end;
}
span.hr-line {
	width: 1px;
	height: 10px;
	background: #999;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	margin: 25px 0;
	margin-right: 20px;
}
.copy-phn {
	display: none;
}
.f-right.navbar-light .navbar-nav a.active {
	color: #000;
	font-weight: 600;
}
.footer-address .navbar-light .navbar-nav a.active {
	color: #000;
	font-weight: 600;
}
/* meadiaquery starts */
@media (max-width: 1024px) {
	.bottomcopy p,
	.f-right.navbar-light .navbar-nav a,
	.powerby {
		font-size: 14px;
	}
	.copy {
		padding-top: 15px;
	}
}
@media (max-width: 768px) {
	.copy {
		padding-top: 15px;
		display: block;
		width: 100%;
		float: none;
		flex: 100%;
		max-width: 100%;
		text-align: center;
	}
	.displayflex {
		display: flex;
		flex: 100%;
		max-width: 100%;
		justify-content: center;
	}
	.footer-address {
		margin-top: 0;
	}
	#newsletter {
		width: 80%;
		margin-bottom: 50px;
	}
	#newsletter .submit-btn,
	#newsletter .form-control {
		width: 100%;
		margin-bottom: 20px;
	}
	.f-logo.col img {
		margin: 0 auto;
		display: block;
	}
	.section-07 .navbar-nav {
		text-align: center;
		justify-content: center;
	}
	.section-07 .navbar {
		display: block;
		padding: 0 0 10px;
	}
	.navbar-light .navbar-nav a {
		font-size: 15px;
	}
	nav.f-social.navbar.navbar-expand.navbar-light {
		float: none;
	}
	.displayflex {
		display: block;
		flex: 100%;
		max-width: 100%;
		justify-content: center;
	}
	.f-right {
		float: none;
		margin-right: 0;
		text-align: center;
		display: block;
	}
	.f-right.navbar-expand .navbar-nav {
		text-align: center;
		justify-content: center;
	}
	.powerby {
		float: none;
		padding-top: 0;
		text-align: center;
		padding-bottom: 20px;
	}
	.f-logo {
		margin-bottom: 15px;
	}
	.f-right .navbar-light .navbar-nav a {
		padding: 8px 10px;
	}
	.scoial-icon {
		font-size: 24px;
	}
	span.hr-line {
		display: none;
	}
	.footer-address .navbar-light .navbar-nav a {
		padding: 5px 5px 5px;
		font-size: 14px;
	}
	.copy-hide {
		display: none;
	}
	.copy-phn {
		display: block;
	}
	nav.f-right.navbar.navbar-expand.navbar-light,
	.f-right.navbar-light .navbar-nav a {
		padding-bottom: 0;
	}
	.section-07 .col-md-8 {
		max-width: 100%;
		flex: 100%;
	}
	.section-07.footer-address.container-grid.container-fluid .col-md-4 {
		max-width: 100%;
		flex: 100%;
	}
	.formcont .icon-angle-down .btn-primary {
		min-width: auto;
		width: 100%;
	}
}

/* maediaquery ends */
