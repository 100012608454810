#Expdetailexpect h2 {
	text-align: left;
	padding: 0;
	margin-bottom: 20px;
}
#Expdetailexpect h2::after {
	content: none;
}
#Expdetailexpect {
	padding: 50px 0px 0;
}

#Expdetailexpect p {
	padding-bottom: 10px;
	color: #808080;
	line-height: 24px;
	font-size: 14px;
}
.author-wrap {
	margin-bottom: 30px;
}
.wrap-detail {
	margin-top: -220px;
	background: #fff;
	padding: 30px;
}
.Expdetailexpectwrap.drop-line.row {
	margin-top: 40px;
}
/* mediaquery css starts */
@media (max-width: 1440px) {
	.Expdetailexpectwrap h2 {
		font-size: 50px;
		line-height: 72px;
	}
}
@media (max-width: 1024px) {
	.Expdetailexpectwrap h2 {
		font-size: 38px;
		line-height: 50px;
	}
}
@media (max-width: 768px) {
	.expdetailstorybookwrap {
		justify-content: flex-start;
		margin-top: 30px;
	}
	#Expdetailexpect {
		position: relative;
		z-index: -1;
	}
}
@media (max-width: 767px) {
	.Expdetailexpectwrap h2 {
		font-size: 32px;
		line-height: 40px;
	}
	#Expdetailexpect {
		padding: 0 30px;
	}
}

/* mediaquery css ends */
