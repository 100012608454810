.order-booking {
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 30px;
	padding-left: 0;
	margin-bottom: 30px;
}
.orderdetaillist {
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
}
.booking-contact li {
	font-weight: 600;
	display: flex;
}
.order-summary {
	color: #000;
	font-weight: 600;
	padding-left: 2.5rem;
}
.order-summary h2 {
	color: #000;
	font-weight: 600;
}
.card-booking h3 {
	padding: 10px 0px;
}
.booking-contact {
	padding: 0px 40px;
	border-right: 1px solid #d9d9d9;
	margin: 20px 0px;
}
.booking-contact:first-child {
	padding-left: 0px;
}

.booking-contact li {
	font-weight: 600;
	font-size: 14px;
}

.booking-contact li.title {
	font-size: 18px;
}
.booking-contact li svg {
	margin-right: 10px;
}
.title {
	font-size: 20px;
	padding-bottom: 5px;
}
.order-booking h5:not(:last-of-type) {
	margin-bottom: 1rem;
}
.order-booking h5 span {
	color: #b0b0b0;
}
.order-booking h5 .hold-status {
	margin-left: 0.5rem;
	font-weight: 400;
	color: #f15b3b;
}
.no-space {
	padding: 0px;
}
.print-btn {
	cursor: pointer;
}

.info-breakdown {
	display: flex;
	margin: 5px 0;
}
.info-breakdown .icon-wrapper {
	margin: -3px 5px 0 0;
}
.info-breakdown .info-wrapper {
	display: flex;
	flex-wrap: wrap;
}
.info-breakdown .info-wrapper > .info-item {
	margin-right: 1rem;
}
.cancel-wrapper {
	display: flex;
	justify-content: flex-end;
}
/* mediaquery css starts */

@media (max-width: 1024px) {
	.order-summary {
		/* margin-left: 1em; */
	}
	.booking-contact {
		padding: 0px 10px;

		margin: 20px 0px;
	}

	.booking-contact li {
		font-weight: 600;
		font-size: 14px;
	}
	.booking-contact li.title {
		font-size: 16px;
	}
	.order-summary h3 {
		margin-top: 0;
	}
}
@media (max-width: 768px) {
	.order-booking,
	.order-booking .d-flex {
		display: block !important;
	}
	.booking-contact {
		padding: 0px;
		border: 0px;
	}
	.orderdetaillist {
		margin-top: 0px;
		display: inline !important;
	}

	.orderdetaillist .col-md-3,
	.orderdetaillist .col-md-4 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-summary {
		margin-left: 80px;
		max-width: 100%;
		margin: auto;
		float: none;
		padding: 0;
		margin-bottom: 30px;
	}
	.order-booking h5 {
		font-size: 16px;
	}
	.card-booking h3 {
		font-size: 24px;
	}
	.user-details p {
		font-size: 14px;
	}
	.message-box {
		margin: 60px 20px;
	}
	.print-btn {
		margin-bottom: 20px;
	}
}
@media (max-width: 767px) {
	.cancel-wrapper {
		justify-content: flex-start;
	}
}
/* mediaquery css ends */

/* Media queries for Print screen */
@media print {
	.print-btn {
		display: none !important;
	}
	.no-print {
		display: none !important;
	}
	.orderdetaillist .print-inline-blk {
		display: inline-block;
		max-width: max-content;
	}
	.orderdetaillist .print-inline-blk:not(:last-of-type) {
		margin-right: 2rem;
	}
}
