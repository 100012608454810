.checkcircle {
	font-size: 30px;
	margin-left: 10px;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
	background: #50bc20;
	border-radius: 100%;
	border: none;
	box-shadow: none;
}
.solidicon {
	font-size: 20px;
	color: #000;
	display: inline-block;
	vertical-align: middle;
	border-radius: 100%;
	margin-right: 10px;
	margin-top: 2px;
}
.textequalspace {
	padding-top: 25px;
}
.reservation-map p {
	font-size: 18px;
	line-height: 24px;
	color: #8e8e8e;
}
.txtblack {
	color: #000;
}
.reservation-map {
	width: auto;
}
h3.txt-orange.textequalspace {
	padding-top: 0;
}
/* meadiaquery starts */
@media (max-width: 768px) {
	.reservation-map .col-md-6 {
		width: 100%;
		flex: 100%;
		max-width: 100%;
	}
	.reservation-map-img {
		padding-top: 30px;
	}
}
/* mediaquery ends */
